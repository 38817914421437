import axios, { AxiosResponse } from 'axios';

// default types
import { defaultPlayerValidation } from '../types/default/defaultPlaylist';
import { playlistTypes } from '../types/playlistTypes';

// interfaces
import {
    Player,
    PlayerData,
    PlayerStyle,
    PlayerStylePlaylist,
    PlayerType
} from '../interfaces/player/player.interface';
import { PlayerRequestValidation, Topic } from '../interfaces/player/playerRequest.interface';
import { PlaylistRequestBody } from '../interfaces/playlist/playlistRequestBody.interface';


export const getPlaylist = async (
    url: string,
    publicPlaylist: boolean,
    params: object,
    body: PlaylistRequestBody,
    topicRequestUrl: string,
    backendAuth: string = '',
    queryPlayerType?: string | undefined
): Promise<PlayerRequestValidation> => {
    try {
        let resp: AxiosResponse<any, any>;

        if (publicPlaylist) {
            // GET /playlist/recent
            resp = await axios(url, {
                headers: { 'apikey': backendAuth },
                params
            });

        } else {
            // POST /playlist
            resp = await axios.post(url, body, {
                headers: { 'apikey': backendAuth },
                params
            });
        }

        // status has to be 2xx
        if (resp.status.toString().charAt(0) !== '2') {
            console.error(`Playlist response validation error. Status: ${resp.status}`)
            return {
                ...defaultPlayerValidation,
                validationResult: resp.statusText
            }
        }

        const topicResp = await axios(topicRequestUrl);
        const topicList: Topic[] = topicResp.data || [];

        const { type, style, data }: { type: PlayerType, style: PlayerStyle, data: PlayerData } = resp.data;
        const playlistStyle = style?.playlist as PlayerStylePlaylist;

        // fill missing fields for player state
        const { audioQueue, currentAudio } = data;
        if (currentAudio) {
            currentAudio.autoPlay = false;
            currentAudio.selected = false;
        }
        audioQueue.forEach(audio => {
            audio.autoPlay = false;
            audio.selected = false;
        })

        const playerType = defaultPlayerValidation.playerType as PlayerType;
        let playerStyle = defaultPlayerValidation.playerStyle as PlayerStyle;


        // use type from url param if provided
        const typeDesign = queryPlayerType ? queryPlayerType : type.design;

        // try to fetch the requested style
        const player: Player = playlistTypes.filter(
            p => p.type.mode === type.mode && p.type.design === typeDesign
        )[0];

        if (player) playerStyle = player?.style as PlayerStyle;

        const validationResult: PlayerRequestValidation = {
            ...defaultPlayerValidation,
            isValidated: true,
            validationResult: 'Successful validation',
            playerType: {
                ...playerType,
                ...type
            },
            playerStyle: {
                visual: {
                    ...playerStyle?.visual,
                    ...style?.visual
                },
                buttons: {
                    ...playerStyle?.buttons,
                    ...style?.buttons
                },
                language: {
                    ...playerStyle?.language,
                    ...style?.language
                },
                playlist: {
                    ...playerStyle?.playlist,
                    ...playlistStyle
                }
            },
            playerData: {
                ...defaultPlayerValidation?.playerData,
                ...data
            },
            topicList
        }

        return validationResult;

    } catch (error) {
        console.error(error)
        throw new Error(error as string)
    }

}