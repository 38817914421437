import { PlayerState } from "../../interfaces/player/player.interface";


export const defaultPlayerState: PlayerState = {
    adLoaded: false,
    adPlayed: false,
    adPlayer: true,
    adPlaying: false,
    adDuration: 0,
    adSkippable: false,
    adSkipTimeOffset: -1,
    controls: false,
    duration: 0,
    isAlreadyPlayed: false,
    isBuffering: false,
    isReady: false,
    light: false,
    loadAgain: true,
    loaded: 0,
    loadedSeconds: 0.0,
    loop: false,
    muted: false,
    pip: false,
    playbackRate: 1.0,
    playbackRateLabel: '1x',
    played: 0.0,
    playedSeconds: 0,
    playedSecondList: [],
    playing: false,
    seeking: false,
    url: '',
    volume: 0.8,
    playNext: false,
    topic: '',
    topicIndex: -1,
    isArticleTextDisplayed: false
}
