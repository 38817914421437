// default types
import { defaultPlayerValidation } from '../types/default/defaultPlaylist';

// utils
import { applyURLStyle } from './applyURLStyle';
import { getPlaylist } from './getPlaylist';

// interfaces
import { PlayerRequestValidation } from '../interfaces/player/playerRequest.interface';
import { PlaylistRequestBody } from '../interfaces/playlist/playlistRequestBody.interface';
import { PlaylistRequestParams } from '../interfaces/playlist/playlistRequest.interface';


const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

export const validatePlaylistData = async (params: any, demo: boolean = false): Promise<PlayerRequestValidation> => {
    const { lang, userId, playlistName, publisher }: PlaylistRequestParams = params.params;
    const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';
    const backendAuth: string = process.env.REACT_APP_BACKEND_AUTH || '';

    const url = new URL(params.request.url);
    const queryPlayerType: string = url.searchParams.get("type") || '';
    const playlistLength: string = url.searchParams.get("playlistLength") || '0';
    const maxDaysOld: string = url.searchParams.get("maxDaysOld") || '365';

    defaultPlayerValidation.language = (lang === '' ? defaultLanguage : lang);

    if (userId === '' || backendRoute === '') {
        return defaultPlayerValidation;
    }

    const requestUrl = `${backendRoute}/playlist`;
    const publicPlaylist = false;
    const requestParams = {
        client: (demo ? 'demo' : userId)
    }

    const requestBody: PlaylistRequestBody = {
        playlistName: playlistName || (demo ? 'demo' : ''),
        publisher: {
            code: publisher || undefined
        },
        playlistLength: Number(playlistLength) || undefined,
        article: {
            limitByPublisher: (userId && !playlistName) ? 20 : 0,
            maxDaysOld: maxDaysOld ? Number(maxDaysOld) : undefined
        },
        getArticleSections: queryPlayerType === 'portal' ? true : false
    };
    const topicRequestUrl = `${backendRoute}/topics`
    const validationResult = await getPlaylist(
        requestUrl, publicPlaylist, requestParams, requestBody, topicRequestUrl, backendAuth, queryPlayerType
    );

    return applyURLStyle(validationResult);
}
