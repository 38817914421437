import { useContext } from 'react';

// contexts
import { PlayerContext } from '../../../contexts/PlayerContext';

// components
import { SpeedRateSelectorButton } from './SpeedRateSelectorButton';
import { SpeedRateSelectorMenu } from './SpeedRateSelectorMenu';

// interfaces
import { PlaybackRate } from '../../../interfaces/player/playbackRate.interface';


const playbackRates: PlaybackRate[] = [
    {
        id: 0,
        playbackRate: 0.5,
        playbackRateLabel: '0.5x'
    },
    {
        id: 1,
        playbackRate: 0.8,
        playbackRateLabel: '0.8x'
    },
    {
        id: 2,
        playbackRate: 1,
        playbackRateLabel: '1x'
    },
    {
        id: 3,
        playbackRate: 1.2,
        playbackRateLabel: '1.2x'
    },
    {
        id: 4,
        playbackRate: 1.5,
        playbackRateLabel: '1.5x'
    },
    {
        id: 5,
        playbackRate: 2,
        playbackRateLabel: '2x'
    },
]


export const SpeedRateSelector = () => {
    const playerContext = useContext(PlayerContext);
    const { type } = playerContext;

    return (
        <div>
            {
                type.mode === 'player' &&
                <SpeedRateSelectorButton playbackRates={playbackRates} />}
            {
                type.mode === 'playlist' &&
                <SpeedRateSelectorMenu playbackRates={playbackRates} />
            }
        </div>
    );
}
