import { useContext, useEffect } from 'react';

// modules
import { useLoaderData } from 'react-router-dom';

// components
import { OdiaPlayer } from '../player/OdiaPlayer';
import { TopBar } from '../topBar/TopBar';

// contexts
import { AppContext } from '../../contexts/AppContext';

// interfaces
import { PlayerRequestValidation } from '../../interfaces/player/playerRequest.interface';
import { PlayerAudio, PlayerStyle } from '../../interfaces/player/player.interface';

// types
import { playerTypes } from '../../types/playerTypes';
import { defaultAudio } from '../../types/default/defaultAudio';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    CssBaseline,
    Stack,
    Typography,
} from '@mui/material';


export const DemoPlayerPage = () => {
    const theme = useTheme();
    const { palette } = theme;

    const validationResult = useLoaderData() as PlayerRequestValidation;

    const { language: requestLanguage } = validationResult;

    const { appLang } = useContext(AppContext);
    const { lang, setLang } = appLang;

    useEffect(() => {
        if (lang.current !== requestLanguage) {
            setLang({
                ...lang,
                current: requestLanguage
            })
        }
    }, [lang, requestLanguage, setLang])

    // audio sample
    const audioQueue: PlayerAudio[] = [
        {
            ...defaultAudio,
            id: 1,
            language: 'en-US',
            publisherTitle: 'Sample audio',
            publisherDescription: 'Sample description',
            duration: 270,
            audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-9.mp3',
            order: 1
        },
        {
            ...defaultAudio,
            id: 2,
            language: 'fr-FR',
            publisherTitle: 'Musique de demo',
            publisherDescription: 'Description de l\'audio',
            duration: 270,
            audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-8.mp3',
            order: 2
        },
        {
            ...defaultAudio,
            id: 3,
            language: 'es-ES',
            publisherTitle: 'Título de ejemplo',
            publisherDescription: 'Descripción de ejemplo',
            duration: 270,
            audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-7.mp3',
            order: 3
        }
    ]

    // player simple default
    const playerSimple = playerTypes.filter(p =>
        p.type.mode === 'player' && p.type.design === 'simple'
    );
    const playerSimpleType = playerSimple[0].type;
    const playerSimpleStyle: PlayerStyle = { ...playerSimple[0].style }

    // player simple custom
    const playerSimpleStyleCustom: PlayerStyle = {
        ...playerSimple[0].style,
        buttons: {
            ...playerSimple[0].style.buttons,
            showPlayerForwardButton: false,
            showPlayerRewindButton: false
        },
        visual: {
            ...playerSimple[0].style.visual,
            primaryColor: '#2D4D6B',
            backgroundColor: '#E6F3FF',
            showOdiaLabel: false,
            showPlayerTitle: false,
            showHeadsetIcon: false
        },
        language: {
            ...playerSimple[0].style.language,
            displayRow: false
        }
    }


    // player compact default
    const playerCompact = playerTypes.filter(p =>
        p.type.mode === 'player' && p.type.design === 'compact'
    );
    const playerCompactType = playerCompact[0].type;
    const playerCompactStyle: PlayerStyle = { ...playerCompact[0].style }


    // player compact custom gray
    const playerCompactStyleCustom1: PlayerStyle = {
        ...playerCompact[0].style,
        buttons: {
            ...playerCompact[0].style.buttons,
            showPlayerSpeedButton: false
        },
        visual: {
            ...playerCompact[0].style.visual,
            primaryColor: '#555',
            showOdiaLabel: true,
            showOdiaLabelIcon: true
        },
        language: {
            ...playerCompact[0].style.language,
            displayRow: true
        }
    }

    // player compact custom blue
    const playerCompactStyleCustom2: PlayerStyle = {
        ...playerCompact[0].style,
        buttons: {
            ...playerCompact[0].style.buttons,
            showPlayerForwardButton: true,
            showPlayerSpeedButton: false
        },
        visual: {
            ...playerCompact[0].style.visual,
            primaryColor: '#2D4D6B',
            backgroundColor: '#E6F3FF',
            showOdiaLabelIcon: true,
            showListenLabel: false
        }
    }

    // player compact custom green
    const playerCompactStyleCustom3: PlayerStyle = {
        ...playerCompact[0].style,
        buttons: {
            ...playerCompact[0].style.buttons,
            showPlayerForwardButton: true,
            showPlayerRewindButton: true,
            showPlayerSpeedButton: false,

        },
        visual: {
            ...playerCompact[0].style.visual,
            primaryColor: '#195747',
            backgroundColor: '#E2F7F1',
            showHeadsetIcon: false
        }
    }

    return (
        <>
            <CssBaseline />
            <TopBar visualStyle={{ showHomeButton: false, showOdiaLabel: false, linkToWebsite: false }} />
            <Box sx={{ p: theme.spacing(1) }}>
                <Stack direction={'column'} spacing={2}>

                    {/* Simple Player default demo */}
                    <Typography
                        data-test="demo-player-simple-label"
                        variant='h4'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(4) }}
                    >
                        Simple player
                    </Typography>
                    <OdiaPlayer
                        playerType={playerSimpleType}
                        playerStyle={playerSimpleStyle}
                        audioQueue={audioQueue}
                    />

                    {/* Simple Player customization demo */}
                    <Typography
                        data-test="demo-player-simple-custom-label"
                        variant='h6'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(1) }}
                    >
                        Simple player customizations
                    </Typography>
                    <OdiaPlayer
                        playerType={playerSimpleType}
                        playerStyle={playerSimpleStyleCustom}
                        audioQueue={audioQueue}
                    />


                    {/* Compact Player default demo */}
                    <Typography
                        data-test="demo-player-compact-label"
                        variant='h4'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(6) }}
                    >
                        Compact player
                    </Typography>
                    <OdiaPlayer
                        playerType={playerCompactType}
                        playerStyle={playerCompactStyle}
                        audioQueue={audioQueue}
                    />

                    {/* Compact Player customization demos */}
                    <Typography
                        data-test="demo-player-compact-custom-label"
                        variant='h6'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(1) }}
                    >
                        Compact player customizations
                    </Typography>
                    <OdiaPlayer
                        playerType={playerCompactType}
                        playerStyle={playerCompactStyleCustom1}
                        audioQueue={audioQueue}
                    />
                    <OdiaPlayer
                        playerType={playerCompactType}
                        playerStyle={playerCompactStyleCustom2}
                        audioQueue={audioQueue}
                    />
                    <OdiaPlayer
                        playerType={playerCompactType}
                        playerStyle={playerCompactStyleCustom3}
                        audioQueue={audioQueue}
                    />
                </Stack>
            </Box>
        </>
    )
}
