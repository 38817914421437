const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition
const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

const getLangCode = (language: string): string => {
    switch (language) {
        case 'en-US':
            return 'en-US'
        case 'fr-FR':
            return 'fr-FR'
        case 'es-ES':
            return 'es-ES'
        default:
            return 'en-US'
    }
}


function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


export const getSpeech = (grammar: string, language = defaultLanguage) => {
    if (!iOS() && ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
        const langCode = getLangCode(language);
        const recognition = new SpeechRecognition();
        const recognitionList = new SpeechGrammarList();

        recognitionList.addFromString(grammar, 1)
        recognition.grammars = recognitionList
        recognition.lang = langCode
        recognition.continuous = true;
        recognition.interimResults = false;
        recognition.maxAlternatives = 2;

        return recognition;
    }
    else
        return null;
}