// shared interfaces
import { PlayerCustom } from '../interfaces/player/playerTypes.interface';

// defaults
import {
    defaultButtons,
    defaultLanguage,
    defaultVisual,
    defaultPlaylist
} from './default/defaultProps';


export const playlistTypes: PlayerCustom[] = [
    {
        type: {
            id: 1,
            mode: 'playlist',
            design: 'simple',
            description: 'Default Simple Playlist',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                backgroundColor: '#FDFDFD',
                boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.08)',
                headerColor: '#000000',
                languageButtonDisplayRight: false,
                playButtonIconCircle: true,
                primaryColor: '#B20023',
                showHeadsetIcon: false,
            },
            buttons: {
                ...defaultButtons,
                showPlayerNextAudioButton: true,
                showPlayerPreviousAudioButton: true,
            },
            language: {
                ...defaultLanguage
            },
            playlist: {
                ...defaultPlaylist
            }
        }
    },
    {
        type: {
            id: 2,
            mode: 'playlist',
            design: 'compact',
            description: 'Default Compact Playlist',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                backgroundColor: '#FDFDFD',
                boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.08)',
                headerColor: '#000000',
                languageButtonDisplayRight: false,
                playButtonIconCircle: true,
                primaryColor: '#B20023',
                showHeadsetIcon: false,
            },
            buttons: {
                ...defaultButtons,
                showPlayerNextAudioButton: true,
                showPlayerPreviousAudioButton: true,
            },
            language: {
                ...defaultLanguage
            },
            playlist: {
                ...defaultPlaylist
            }
        }
    },
    {
        type: {
            id: 3,
            mode: 'playlist',
            design: 'demo',
            description: 'Demo Playlist (same design as the Simple Playlist)',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                backgroundColor: '#FDFDFD',
                boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.08)',
                headerColor: '#000000',
                languageButtonDisplayRight: false,
                playButtonIconCircle: true,
                primaryColor: '#B20023',
                showHeadsetIcon: false,
                showPlayerDescription: false,
                truncatePlayerTitle: true,
            },
            buttons: {
                ...defaultButtons,
                showPlayerNextAudioButton: true,
                showPlayerPreviousAudioButton: true,
            },
            language: {
                ...defaultLanguage
            },
            playlist: {
                ...defaultPlaylist,
                showPlaylistAudioDescription: false,
                showPlaylistHeader: true,
            }
        }
    },
    {
        type: {
            id: 4,
            mode: 'playlist',
            design: 'large',
            description: 'Demo Large Playlist (organized by topic)',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                backgroundColor: '#FDFDFD',
                boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.08)',
                headerColor: '#000000',
                languageButtonDisplayRight: false,
                playButtonIconCircle: true,
                primaryColor: '#B20023',
                showHeadsetIcon: false,
                showOdiaLabel: false,
                showOdiaLabelIcon: false,
                showPlayerDescription: false,
                truncatePlayerTitle: true,
            },
            buttons: {
                ...defaultButtons,
                showPlayerNextAudioButton: true,
                showPlayerPreviousAudioButton: true,
            },
            language: {
                ...defaultLanguage
            },
            playlist: {
                ...defaultPlaylist,
                showPlaylistAudioTopic: false,
                showPlaylistHeader: true,
            }
        }
    },
    {
        type: {
            id: 5,
            mode: 'playlist',
            design: 'complete',
            description: 'Demo Complete Playlist skin for car display',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.08)',
                buttonsSize: 'large',
                languageButtonDisplayRight: false,
                playButtonIconCircle: true,
                playButtonIconSize: '6rem',
                showHeadsetIcon: false,
                showOdiaLabel: false,
                showOdiaLabelIcon: false,
                showPlayerDescription: false,
                truncatePlayerTitle: true,
            },
            buttons: {
                ...defaultButtons,
                showPlayerForwardButton: false,
                showPlayerLanguageButton: true,
                showPlayerNextAudioButton: true,
                showPlayerPreviousAudioButton: true,
                showPlayerRewindButton: false,
            },
            language: {
                ...defaultLanguage
            },
            playlist: {
                ...defaultPlaylist,
                showPlaylistAudioTopic: false,
                showPlaylistHeader: true,
            }
        }
    },
    {
        type: {
            id: 6,
            mode: 'playlist',
            design: 'mobile',
            description: 'Default Mobile Playlist',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                backgroundColor: '#FDFDFD',
                boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.08)',
                headerColor: '#000000',
                languageButtonDisplayRight: false,
                playButtonIconCircle: true,
                primaryColor: '#B20023',
                showHeadsetIcon: false,
                showOdiaLabel: false,
                showOdiaLabelIcon: false,
            },
            buttons: {
                ...defaultButtons,
                showPlayerNextAudioButton: true,
                showPlayerPreviousAudioButton: true,
            },
            language: {
                ...defaultLanguage
            },
            playlist: {
                ...defaultPlaylist,
                showPlaylistHeader: true,
                showPlaylistToggleViewButtons: false,
            }
        }
    }
]