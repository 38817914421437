
// speech
import { grammarEN } from '../../speech/grammar_en';
import { grammarES } from '../../speech/grammar_es';
import { grammarFR } from '../../speech/grammar_fr';
import { speechCommandsEN } from '../../speech/speechCommands_en';
import { speechCommandsES } from '../../speech/speechCommands_es';
import { speechCommandsFR } from '../../speech/speechCommands_fr';


interface ReturnData {
    speechCommands: string[],
    speechGrammar: string
}

const commands = {
    'en': speechCommandsEN,
    'es': speechCommandsES,
    'fr': speechCommandsFR
}
const grammar = {
    'en': grammarEN,
    'es': grammarES,
    'fr': grammarFR
}


export const getSpeechCommands = (language: string): ReturnData => {
    const langCode = language.substring(0, 2);

    let speechCommands = [''];
    let speechGrammar = '';

    const langCommands = commands[langCode as keyof typeof commands];
    const langGrammar = grammar[langCode as keyof typeof grammar];

    speechCommands = langCommands?.player;
    speechGrammar = langGrammar?.player;

    return { speechCommands, speechGrammar }
}
