import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#efefef',
      light: '#dadce0'
    },
    secondary: {
      main: '#d60000',
      light: '#b20023'
    },
    info: {
      main: '#d32f2f'
    },
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3c4043',
      light: '#3c4043'
    },
    secondary: {
      main: '#d60000',
      light: '#ef4545'
    },
    info: {
      main: '#c93a3a'
    },
    text: {
      secondary: '#bababa'
    },
    background: {
      paper: '#272727'
    },
  }
});