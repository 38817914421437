import { useState } from 'react';

// router
import { AppRouter } from './router/AppRouter';

// context
import { AppContext } from './contexts/AppContext';

// translation module
import { IntlProvider } from "react-intl";

// app style theme
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme/appTheme';

// translation files
import messages_en from './translations/en.json';
import messages_es from './translations/es.json';
import messages_fr from './translations/fr.json';

// default data
import { defaultVoiceCommand } from './types/default/defaultVoiceCommand';

// interfaces
import { Topic } from './interfaces/player/playerRequest.interface';
import { AppContextIF } from './interfaces/appContext/appContext.interface';


// TODO: change to capture locale and map it with the available languages
const locale = navigator.language || 'en';

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

const App = () => {
  const [lang, setLang] = useState({
    current: defaultLanguage,
    default: defaultLanguage
  });

  const [topics, setTopics] = useState([] as Topic[]);
  const appTopics = {
    topics,
    setTopics
  }

  const defaultThemeMode = {
    mode: 'light',
    theme: lightTheme
  };
  const [themeMode, setThemeMode] = useState(defaultThemeMode);
  const [isRecording, setRecording] = useState(false);

  const [voiceCommand, setVoiceCommand] = useState(defaultVoiceCommand);
  const [audioGuideActivated, setAudioGuideActivated] = useState(false);
  const [audioGuideShown, setAudioGuideShown] = useState(false);

  const [openLandingModal, setOpenLandingModal] = useState(true);
  const [openTopicModal, setOpenTopicModal] = useState(false);

  const defaultExternalCommand = {
    message: '',
    languageCode: '',
    pendingProcessing: false
  }
  const [command, setCommand] = useState(defaultExternalCommand);
  const [playAudioGuide, setPlayAudioGuide] = useState(false);
  const [audioGuideNextSrc, setAudioGuideNextSrc] = useState('');


  const toggleColorMode = () => {
    setThemeMode(prev => (
      prev.mode === 'light'
        ? {
          mode: 'dark',
          theme: darkTheme
        }
        : {
          mode: 'light',
          theme: lightTheme
        }
    ));
  }

  const setDarkMode = () => {
    setThemeMode({
      mode: 'dark',
      theme: darkTheme
    });
  }

  const setLightMode = () => {
    setThemeMode({
      mode: 'light',
      theme: lightTheme
    });
  }

  const appDefaultContext: AppContextIF = {
    appLang: {
      lang,
      setLang
    },
    appRecording: {
      isRecording,
      setRecording,
      voiceCommand,
      setVoiceCommand
    },
    audioGuide: {
      audioGuideActivated,
      setAudioGuideActivated,
      audioGuideShown,
      setAudioGuideShown,
      audioGuideNextSrc,
      setAudioGuideNextSrc
    },
    colorMode: {
      themeMode,
      toggleColorMode,
      setDarkMode,
      setLightMode
    },
    appTopics,
    appModals: {
      landingModal: {
        openLandingModal,
        setOpenLandingModal
      },
      topicModal: {
        openTopicModal,
        setOpenTopicModal
      }
    },
    externalCommand: {
      command,
      setCommand,
      playAudioGuide,
      setPlayAudioGuide
    }
  };

  // messages for translations
  const messages = {
    en: messages_en,
    es: messages_es,
    fr: messages_fr
  };


  return (
    <ThemeProvider theme={themeMode.theme}>
      <AppContext.Provider value={appDefaultContext}>
        <IntlProvider
          locale={locale}
          messages={messages[lang.current.substring(0, 2) as keyof typeof messages]}
        >
          <AppRouter />
        </IntlProvider>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
