import { useContext } from 'react';

// components
import { AudioDescriptorMobile } from '../../../audioDescriptorMobile/AudioDescriptorMobile';

// contexts
import { AppContext } from '../../../../../contexts/AppContext';
import { PlayerContext } from '../../../../../contexts/PlayerContext';

// interfaces
import { PlayerData, PlayerStylePlaylist } from '../../../../../interfaces/player/player.interface';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { PlayArrowRounded } from '@mui/icons-material';

// actions
import { handleAudioSelect } from '../../../playlistHandlers';


export const PlaylistQueueListMobile = () => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const playerContext = useContext(PlayerContext);
    const { style, data } = playerContext;

    const { audioQueue } = data as PlayerData;
    const { visual, playlist } = style;

    const { primaryColor, secondaryColor } = visual;

    const {
        showPlaylistAudioTitle,
        showPlaylistAudioDate,
        showPlaylistAudioNumber,
        showPlaylistAudioPublisher,
        showPlaylistAudioTopic,
        showPlaylistPlayIcon,
        showPlaylistImages,
    } = playlist as PlayerStylePlaylist;


    const languageAudios = audioQueue.sort((a, b) => a.order - b.order).filter(
        audio => audio.language.substring(0, 2) === currentContextLanguage.substring(0, 2) && audio.visible
    )


    return (
        <Box px={0} py={1}>
            {
                languageAudios?.map(audio =>
                    <Box
                        key={audio.id}
                        component={'div'}
                        width={1}
                        pl={{ xs: -2, sm: -1, md: 0 }}
                        pr={0}
                        py={1}
                        sx={{
                            borderRadius: 2,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: palette.mode === 'dark' ? palette.grey[900] : palette.grey[300],
                            }
                        }}
                        onClick={() => handleAudioSelect(audio.id, playerContext, currentContextLanguage)}
                    >
                        <Stack direction='row'>
                            {
                                showPlaylistPlayIcon &&
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <IconButton
                                        aria-label={`playlist-play-${audio.order}`}
                                        disabled
                                        sx={{ pl: 0 }}
                                    >
                                        <PlayArrowRounded />
                                    </IconButton>
                                </Box>
                            }
                            {
                                showPlaylistAudioNumber &&
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    pr={1}
                                >
                                    <Typography
                                        aria-label={`playlist-order-${audio.order}`}
                                        color={audio.selected ? primaryColor : secondaryColor}
                                        fontWeight={600}
                                        variant="body1"
                                    >
                                        {audio.order}
                                    </Typography>
                                </Box>
                            }

                            <AudioDescriptorMobile
                                audio={audio}
                                visualStyle={{
                                    showTitle: showPlaylistAudioTitle,
                                    showDate: showPlaylistAudioDate,
                                    showPublisher: showPlaylistAudioPublisher,
                                    showTopic: showPlaylistAudioTopic,
                                    showImage: showPlaylistImages,
                                    imageSize: '100px',
                                    highlightSelectedAudio: true,
                                    addPublisherLink: false,
                                }}
                            />
                        </Stack>
                    </Box>
                )}
        </Box >
    )
}
