export interface checkedColor {
    isValid: boolean,
    color: string,
    format: string
}

export const checkColor = (color: string): checkedColor => {
    const style = new Option().style;
    style.color = color;

    let checked: checkedColor;

    if(color.length > 0 && style.color === color) checked = {isValid: true, color, format: "string"};

    else if (/^#[0-9A-F]{3}$/i.test('#' + color)) checked = {isValid: true, color: '#' + color, format: "hex"};

    else if(/^#[0-9A-F]{6}$/i.test('#' + color)) checked = {isValid: true, color: '#' + color, format: "hex"};

    else checked = {isValid: false, color, format: "invalid"};

    return checked;
}
