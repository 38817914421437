import { defaultAudio } from "./defaultAudio";

import { playlistTypes } from "../playlistTypes";

// interfaces
import { Player, PlayerStylePlaylist } from "../../interfaces/player/player.interface";
import { PlayerRequestValidation } from "../../interfaces/player/playerRequest.interface";

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

export const defaultPlaylist: Player = playlistTypes.filter(p => p.type.mode === 'playlist' && p.type.design === 'simple')[0];

const defaultPlayerPlaylist = defaultPlaylist.style.playlist as PlayerStylePlaylist;
export const defaultPlayerValidation: PlayerRequestValidation = {
    isValidated: false,
    validationResult: 'Could not validate the request',
    language: defaultLanguage,
    playerType: { ...defaultPlaylist.type },
    playerStyle: {
        visual: { ...defaultPlaylist.style.visual },
        buttons: { ...defaultPlaylist.style.buttons },
        language: { ...defaultPlaylist.style.language },
        playlist: { ...defaultPlayerPlaylist }
    },
    playerData: {
        currentAudio: defaultAudio,
        audioQueue: []
    },
    topicList: []
}