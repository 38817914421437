import { useContext } from 'react';

// contexts
import { AppContext } from '../../../../contexts/AppContext';

// modules
import { FormattedMessage } from "react-intl";

// mui
import { useTheme } from '@mui/material/styles';

import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Stack,
    Typography
} from "@mui/material";


interface Props {
    topicId: number,
    setModalOpen: (value: React.SetStateAction<boolean>) => void,
    handleTopicSelect: Function
}


export const TopicModalCard = ({ topicId, setModalOpen, handleTopicSelect }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appTopics } = useContext(AppContext);
    const { topics: topicList } = appTopics;

    const topicData = topicList.filter(t => t.id === topicId)[0]

    const defaultTopic = topicList.filter(t => t.id === 0)[0]

    const id = topicData ? topicData.id : defaultTopic.id;
    const description = topicData ? topicData.label : defaultTopic.label;
    const imgUrl = topicData ? topicData.thumbnailUrl : defaultTopic.thumbnailUrl;

    const handleTopicSelection = (topic: string) => {
        setModalOpen(false)
        handleTopicSelect(topic)
    }


    return (
        <Grid
            item
            xs={12}
            md={6}
            lg={3}
            margin={3}
        >
            <Card
                sx={{
                    borderRadius: 20,
                    minHeight: 150,
                    minWidth: 250,
                    position: 'relative',
                }}
            >
                <CardMedia
                    alt={description}
                    component="img"
                    src={imgUrl}
                    title={`${description}-news`}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: "100%",
                        width: "100%",
                        opacity: 0.5,
                        filter: 'grayscale(70%)',
                    }}
                />
                <CardActionArea
                    id={`topic-${description}-card`}
                    onClick={() => handleTopicSelection(description)}
                    sx={{
                        position: 'relative',
                        minHeight: 150
                    }}
                >
                    <CardContent
                        sx={{
                            backgroundColor: "transparent",
                            color: palette.info.main,
                            p: 4,
                        }}
                    >
                        <Stack
                            alignItems='center'
                            direction='column'
                            spacing={2}
                        >
                            <Typography
                                variant="h3"
                                component="div"
                                sx={{
                                    textShadow: palette.mode === 'dark'
                                        ? '0 2px 12px rgba(0, 0, 0, 0.9)'
                                        : '0 2px 12px rgba(255, 255, 255, 0.9)',
                                }}
                            >
                                <FormattedMessage id={`app.speech-topics-id-${id}`} />
                            </Typography>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}
