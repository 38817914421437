import { useContext } from "react";

// external modules
import { FormattedMessage } from "react-intl";

// components
import { LangSelector } from "../langSelector/LangSelector";
import { LandingPageModal } from "../playlist/completePlaylist/landingPageModal/LandingPageModal";

// contexts
import { AppContext } from "../../contexts/AppContext";
import { PlayerContext } from "../../contexts/PlayerContext";

// mui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Typography,
  Toolbar,
  Tooltip,
  Button,
} from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import HomeIcon from "@mui/icons-material/Home";

// img
import logoOdia from "../../img/logo-odia-original.png";
import logoOdiaLight from "../../img/logo-odia-original-light.png";

// types
import { defaultButtons } from "../../types/default/defaultProps";
import { AudioGuide } from "../speechPage/audioGuide/AudioGuide";
import { MobileOrientation } from "../../interfaces/playlist/mobile.interface";

interface Props {
  visualStyle: VisualStyle;
  mobileViewOrientation?: MobileOrientation;
}
interface VisualStyle {
  showOdiaLabel: boolean;
  showHomeButton: boolean;
  linkToWebsite: boolean;
}

export const TopBar = ({
  visualStyle,
  mobileViewOrientation = "portrait",
}: Props) => {
  const theme = useTheme();
  const { palette } = theme;
  const { showHomeButton, linkToWebsite } = visualStyle;

  const { colorMode, appModals, audioGuide, appLang } = useContext(AppContext);
  const playerContext = useContext(PlayerContext);

  const { lang } = appLang;
  const { audioGuideShown } = audioGuide;
  const { toggleColorMode } = colorMode;
  const { landingModal } = appModals;
  const { openLandingModal, setOpenLandingModal } = landingModal;
  const { style, data } = playerContext;

  const backgroundColor = style?.visual?.backgroundColor || "inherit";

  const bgColor =
    palette.mode !== "dark" ? backgroundColor : palette.background.paper;

  const showPlayerLanguageButton =
    style?.buttons?.showPlayerLanguageButton ||
    defaultButtons.showPlayerLanguageButton;
  const audioQueue = data?.audioQueue || [];

  const handleClick = () => {
    setOpenLandingModal(true);
  };

  return (
    <>
      <AppBar
        data-test="appbar"
        position="static"
        sx={{
          background:
            mobileViewOrientation === "portrait" ? "primary" : bgColor,
          borderRadius: mobileViewOrientation === "portrait" ? "inherit" : 3,
          ml: mobileViewOrientation === "portrait" ? "inherit" : 1,
          mt: mobileViewOrientation === "portrait" ? "inherit" : 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {showHomeButton && (
              <Button onClick={handleClick} color="inherit" sx={{ mx: 3 }}>
                <HomeIcon sx={{ fontSize: 40 }} />
              </Button>
            )}

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                pl: mobileViewOrientation === "portrait" ? 3 : 0,
              }}
            >
              {linkToWebsite ? (
                <a
                  href={`https://www.odialab.com/${lang.current === "fr-FR" ? "fr" : "en"
                    }/`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ height: "30px" }}
                >
                  <img
                    aria-label="logo icon"
                    src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                    alt="logo"
                    style={{ height: "30px" }}
                  />
                </a>
              ) : (
                <img
                  aria-label="logo icon"
                  src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                  alt="logo"
                  style={{ height: "30px" }}
                />
              )}
              {mobileViewOrientation === "portrait" && (
                <Typography
                  color={palette.text.secondary}
                  sx={{
                    display: { xs: "none", sm: "inherit" },
                    ml: 1,
                    pt: 1,
                    fontStyle: "italic",
                  }}
                  variant="caption"
                >
                  <FormattedMessage id="app.playlist-tagline" />
                </Typography>
              )}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {showPlayerLanguageButton && audioQueue.length > 0 && (
                <LangSelector />
              )}
            </Box>

            <Box sx={{ flexGrow: 0, pr: audioGuideShown ? 0 : 3 }}>
              <Tooltip
                placement="bottom"
                title={
                  <FormattedMessage
                    id={
                      palette.mode === "dark"
                        ? "app.speech-theme-dark"
                        : "app.speech-theme-light"
                    }
                  />
                }
              >
                <IconButton
                  id="colorModeButton"
                  sx={{ ml: 1 }}
                  color="inherit"
                  onClick={() => toggleColorMode()}
                >
                  {palette.mode === "dark" ? (
                    <DarkModeIcon />
                  ) : (
                    <LightModeIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>

            {audioGuideShown && (
              <Box sx={{ flexGrow: 0, pr: 3 }}>
                <Tooltip
                  placement="bottom"
                  title={<FormattedMessage id="app.audio-guide" />}
                >
                  <AudioGuide switchType="button" />
                </Tooltip>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      {showHomeButton && (
        <LandingPageModal
          open={openLandingModal}
          setOpen={setOpenLandingModal}
        />
      )}
    </>
  );
};
