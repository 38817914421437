import { useContext, useRef } from 'react';

// modules
import { FormattedMessage } from "react-intl";

// contexts
import { AppContext } from '../../../contexts/AppContext';

// mui
import { alpha, styled } from '@mui/material/styles';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Typography,
    IconButton
} from "@mui/material";

import { RecordVoiceOver, VoiceOverOff } from '@mui/icons-material';

// utils
import { getSpeech } from '../../../utils/speech/getSpeech';
import { getSpeechCommands } from '../../../utils/speech/getSpeechCommands';


const AudioSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.light,
    },
}));

const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';
const audioGuideUrl = `${backendRoute}/audioGuide`

interface Props {
    switchType: 'switch' | 'button';
}

export const AudioGuide = ({ switchType }: Props) => {
    const { appLang, audioGuide } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentLanguage } = lang;
    const { audioGuideActivated, setAudioGuideActivated } = audioGuide;


    //do not show audio guide button on Firefox
    const { speechGrammar } = getSpeechCommands(lang.current)
    const grammar = useRef(speechGrammar);
    const recognition = useRef(getSpeech(grammar.current, currentLanguage));
    let showAudioGuideButton = recognition.current !== null ? true : false;


    const handleSwitchGuide = () => {
        const newAudioGuideActivationStatus: boolean = !audioGuideActivated;
        setAudioGuideActivated(newAudioGuideActivationStatus);
        if (newAudioGuideActivationStatus && switchType === 'button') {
            const audioSrc = `${audioGuideUrl}?lang=${currentLanguage}&context=introduction`;
            const audio: HTMLAudioElement = new Audio(audioSrc);
            audio.play();
        }
    }

    return (
        <>
            {showAudioGuideButton && switchType === 'switch' &&
                <FormGroup>
                    <FormControlLabel
                        control={
                            <AudioSwitch
                                checked={audioGuideActivated}
                                onChange={handleSwitchGuide}
                                name="audioGuide"
                            />
                        }
                        label={
                            <Typography variant='caption'>
                                <FormattedMessage id="app.speech-audio-guide" />
                            </Typography>
                        }
                    />
                </FormGroup>
            }
            {showAudioGuideButton && switchType === 'button' &&
                <FormGroup>
                    <IconButton
                        id="voiceOverButton"
                        sx={{ ml: 1 }}
                        color="inherit"
                        onClick={handleSwitchGuide}
                    >
                        {audioGuideActivated ? <RecordVoiceOver /> : <VoiceOverOff />}
                    </IconButton>
                </FormGroup>
            }
        </>
    )
}


