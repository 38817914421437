import { useContext, useEffect } from 'react';

import { isRouteErrorResponse, useLocation, useRouteError, useSearchParams } from "react-router-dom";

// modules
import { FormattedMessage } from "react-intl";

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Typography } from '@mui/material';
import { AppContext } from '../../contexts/AppContext';
import { DisabledPlayer } from './disabledPlayer/DisabledPlayer';
import { ErrorPageType, ErrorPageTypes, PlayerType } from '../../interfaces/player/player.interface';

interface PlayerDataBody {
    type: PlayerType,
    errorPageType: ErrorPageType
}

export const ErrorPage = () => {
    const theme = useTheme();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const { appLang, colorMode } = useContext(AppContext);
    const { lang, setLang } = appLang;
    const { setDarkMode, setLightMode } = colorMode;

    const error: any = useRouteError();

    const path = location.pathname;

    const requestLanguage = path.split('/', 2).pop() || '';
    const themeQuery = searchParams.get('theme');
    const themeMode = themeQuery || 'light';

    let formatedMessageId = '';
    let errorPageType: ErrorPageTypes = 'error_message';

    if (isRouteErrorResponse(error)) {
        if (error.status === 417) {
            formatedMessageId = "app.error-audio";
        }
    } else {
        if (error?.response?.status === 417) {
            formatedMessageId = "app.error-audio";
        }
        const playerData: PlayerDataBody = error?.response?.data?.playerData;
        if (playerData && playerData.errorPageType && playerData.errorPageType.type) {
            errorPageType = playerData.errorPageType.type
        }

        // errorType url param: override user's default
        if (searchParams.get('errorType')) {
            errorPageType = searchParams.get('errorType') as ErrorPageTypes || errorPageType;
        }
    }

    useEffect(() => {
        if (requestLanguage.length > 0 && lang.current !== requestLanguage) {
            setLang({
                ...lang,
                current: requestLanguage
            })
        }
    }, [lang, requestLanguage, setLang])

    useEffect(() => {
        if (themeMode === 'dark') setDarkMode();
        if (themeMode === 'light') setLightMode();
        // eslint-disable-next-line
    }, [themeMode])


    return (
        <>
            <CssBaseline />
            <Box sx={{ p: theme.spacing(2) }}>
                {
                    // display error message
                    errorPageType === 'error_message' &&
                    <Typography
                        data-test="error-label"
                        variant='h6'
                    >
                        {
                            formatedMessageId
                                ? <FormattedMessage id={formatedMessageId} />
                                : <FormattedMessage id="app.error-loading" />
                        }
                    </Typography>
                }

                {
                    // display blank page
                    errorPageType === 'blank_page' && <></>
                }

                {
                    // display disabled player
                    errorPageType === 'disabled_content' && <DisabledPlayer />
                }
            </Box>
        </>
    )
}
