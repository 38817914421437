// react imports
import { useContext, useState, useEffect, useRef } from 'react';

// components
import { CompactPlaylist } from './compactPlaylist/CompactPlaylist';
import { CompletePlaylist } from './completePlaylist/CompletePlaylist';
import { SimplePlaylist } from './simplePlaylist/SimplePlaylist';
import { MobilePlaylist } from './mobilePlaylist/MobilePlaylist';

// interfaces
import { OdiaPlayerProps } from '../../interfaces/player/odiaPlayer.interface';
import {
    Player,
    PlayerState,
    PlayerProps,
    PlayerAudio,
    PlayerData,
} from '../../interfaces/player/player.interface';

// actions
import {
    formatDuration,
    handleSliderChange,
    handleSliderChangeCommited
} from '../player/odiaPlayerHandlers';
import { handlePlayNext } from './playlistHandlers';

// app context
import { AppContext } from '../../contexts/AppContext';
import { PlayerContext } from '../../contexts/PlayerContext';

// types
import { defaultPlayerState } from '../../types/default/defaultPlayerState';

// utils
import { setAudioFilterVisibility } from '../../utils/setAudioFilterVisibility';
import { setLargePlaylistAudioOrder } from '../../utils/setLargePlaylistAudioOrder';
import { defaultAudio } from '../../types/default/defaultAudio';


export const OdiaPlaylist = (props: OdiaPlayerProps) => {
    const { playerType, playerStyle, audioQueue } = props;
    const { appLang, appTopics } = useContext(AppContext);

    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const { topics: topicList } = appTopics;

    // get audio from current language
    let audioFilter: PlayerAudio[] = [];
    if (audioQueue.length > 1) {
        audioFilter = audioQueue.filter(audio => audio.language.substring(0, 2) === currentContextLanguage.substring(0, 2));
        if (audioFilter.length === 0) audioFilter.push(audioQueue[0])
    } else {
        audioFilter.push(audioQueue[0])
    }


    const currentAudio = useRef({
        ...defaultAudio,
        ...audioFilter[0]
    })

    const playerRef = useRef(null);

    const playerDefaultState: PlayerState = defaultPlayerState;

    const [playerState, setPlayerState] = useState(playerDefaultState);
    const [playerData, setPlayerDataState] = useState({
        currentAudio: currentAudio.current,
        audioQueue
    });
    const [position, setPosition] = useState(0);
    const [showAudioContent, setShowAudioContent] = useState(false);

    const playerProps: PlayerProps = {
        playerRef,
        playerState,
        setPlayerState,
        setPlayerDataState,
        position,
        setPosition,
        handleSliderChange,
        handleSliderChangeCommited,
        formatDuration,
        showAudioTextContent: showAudioContent,
        setShowAudioTextContent: setShowAudioContent

    }

    const player: Player = {
        type: playerType,
        props: playerProps,
        data: playerData,
        style: playerStyle
    }


    useEffect(() => {
        if (currentAudio.current?.audioUrl !== playerState?.url) {
            setPlayerState((state: PlayerState) => ({
                ...state,
                url: currentAudio.current?.audioUrl,
                played: 0,
                loaded: 0,
                duration: 0,
                loadAgain: false,
                playing: false,
                isAlreadyPlayed: false
            }))

            const newQueue = audioQueue.map(audio => {
                audio.id === currentAudio.current.id
                    ? audio.selected = true
                    : audio.selected = false
                return audio
            })

            setPlayerDataState((state: PlayerData) => ({
                ...state,
                currentAudio: currentAudio.current,
                audioQueue: newQueue
            }))
        }

        return () => {
            setPlayerState((state: PlayerState) => ({
                ...state,
                url: '',
                played: 0,
                loaded: 0,
                duration: 0,
                loadAgain: false,
                playing: false,
                isAlreadyPlayed: false,
            }))
        }
        // eslint-disable-next-line
    }, [currentAudio.current, audioQueue])


    useEffect(() => {
        setPosition(playerState.playedSeconds as number)
    }, [playerState.playedSeconds])


    useEffect(() => {
        let languageAudios: PlayerAudio[] = setAudioFilterVisibility(currentContextLanguage, topicList[0]?.label || '', audioQueue);

        if (languageAudios.length > 0) {
            const newCurrentAudio = languageAudios.filter(audio => audio.order === 0)[0];

            if (newCurrentAudio && newCurrentAudio.audioUrl !== playerState.url) {
                const autoPlay = newCurrentAudio.autoPlay;

                setPlayerState((state: PlayerState) => ({
                    ...state,
                    url: newCurrentAudio.audioUrl,
                    playing: autoPlay || state.playing,
                    played: 0,
                    playedSeconds: 0,
                    loaded: 0,
                    loadAgain: false,
                    isAlreadyPlayed: false
                }))
                setPlayerDataState((state) => ({
                    ...state,
                    currentAudio: newCurrentAudio,
                    audioQueue: player.type.design === 'large' ? setLargePlaylistAudioOrder(languageAudios) : languageAudios
                }))
            }
        }
        // eslint-disable-next-line
    }, [currentContextLanguage])


    useEffect(() => {
        // load next audio when current one is finished
        if (playerState.isAlreadyPlayed) {
            handlePlayNext(player);
        }
        // playlistState is not required for this effect
        // eslint-disable-next-line
    }, [playerState.isAlreadyPlayed])

    return (
        <PlayerContext.Provider value={player}>
            {
                player.type.design === 'compact' &&
                <CompactPlaylist />
            }
            {
                (
                    player.type.design === 'simple' ||
                    player.type.design === 'demo'
                ) &&
                <SimplePlaylist view='list' />
            }
            {
                player.type.design === 'large' &&
                <SimplePlaylist view='covers' />
            }
            {
                player.type.design === 'complete' &&
                <CompletePlaylist />
            }
            {
                player.type.design === 'mobile' &&
                <MobilePlaylist />
            }
            {
                player.type.design === 'portal' &&
                <SimplePlaylist view='portal' />
            }

        </PlayerContext.Provider>
    )
}
