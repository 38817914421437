// interfaces
import { VoiceCommand } from "../../interfaces/appContext/appContext.interface";

// utils
import { matchCommand } from "./matchCommand";
import { Player } from '../../interfaces/player/player.interface';
import { mapTopicCommand } from "../../speech/playerCommandsMapping";
import { handleSpeechCommand } from "../../components/speechPage/speechHandlers";
import { Topic } from "../../interfaces/player/playerRequest.interface";


export const processCommand = (
    command: string,
    commandList: string[],
    confidence: number,
    language: string,
    audioGuideActivated: boolean,
    playerContext: Player,
    setVoiceCommand: React.Dispatch<React.SetStateAction<VoiceCommand>>,
    topics: Topic[],
    setAudioGuideNextSrc: React.Dispatch<React.SetStateAction<string>>,
    setPlayAudioGuide: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenTopicModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const matchResult = matchCommand(command, commandList, language);

    const isMatched = matchResult.isMatched;
    command = matchResult.command;

    let playerAction = '';
    let voiceCommand: VoiceCommand = {
        command,
        confidence,
        isMatched,
        playerAction
    }

    if (isMatched) {
        playerAction = matchResult.commandType;
        voiceCommand.playerAction = playerAction;

        // map the topic translation
        if (playerAction === 'topicSelect' && language !== 'en-US') {
            command = mapTopicCommand(command, language);
        }

        handleSpeechCommand(
            playerAction,
            language,
            audioGuideActivated,
            playerContext,
            setOpenTopicModal,
            command,
            topics,
            setAudioGuideNextSrc,
            setPlayAudioGuide
        );
    }

    setVoiceCommand(voiceCommand);
}
