import { PlayerStylePlaylist } from '../../interfaces/player/player.interface';
import {
    PlayerStyleButtons,
    PlayerStyleLanguage,
    PlayerStyleVisual
} from "../../interfaces/player/player.interface";


export const defaultVisual: PlayerStyleVisual = {
    backgroundColor: '#FAFAFA',
    boxShadow: '0px 10px 20px -16px rgba(0,0,0,0.38)',
    buttonsSize: 'medium',
    displayAudioContent: true,
    headerColor: "#000000",
    languageButtonDisplayRight: true,
    linkToWebsite: true,
    playButtonIconCircle: false,
    playButtonIconSize: '3rem',
    primaryColor: '#b20023',
    secondaryColor: '#7F7F7F',
    showCarDisplayModal: false,
    showHeadsetIcon: true,
    showOdiaLabel: true,
    showOdiaLabelIcon: true,
    showPlayerDescription: true,
    showPlayerTitle: true,
    showConsumption: true,
    showListenLabel: true,
    themeMode: 'light',
    truncatePlayerDescription: false,
    truncatePlayerTitle: false,
}

export const defaultButtons: PlayerStyleButtons = {
    showHomeButton: false,
    showPlayerForwardButton: true,
    showPlayerLanguageButton: true,
    showPlayerNextAudioButton: false,
    showPlayerPreviousAudioButton: false,
    showPlayerRewindButton: true,
    showPlayerSpeedButton: true,
    showSpeechButton: false,
    showMuteButton: false
}

export const defaultPlaylist: PlayerStylePlaylist = {
    defaultPlaylistView: 'detail',
    showPlaylistAudioDate: true,
    showPlaylistAudioDescription: true,
    showPlaylistAudioDuration: true,
    showPlaylistAudioNumber: false,
    showPlaylistAudioPublisher: true,
    showPlaylistAudioTitle: true,
    showPlaylistAudioTopic: true,
    showPlaylistHeader: false,
    showPlaylistImages: true,
    showPlaylistPlayIcon: false,
    showPlaylistToggleViewButtons: true,
    showPlaylistTopicSelector: true,
    truncatePlaylistAudioDescription: true,
    truncatePlaylistAudioTitle: true,
}

export const defaultLanguage: PlayerStyleLanguage = {
    displayRow: true,
    showFlag: true,
    showLanguageName: true,
}
