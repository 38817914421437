
// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Grid,
    Modal,
    Stack,
    Typography
} from '@mui/material';

import { buttons } from './buttons';


interface Props {
    open: boolean,
    setOpen: (value: React.SetStateAction<boolean>) => void
}


export const LandingPageModal = ({ open, setOpen }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const handleLandingModalClose = () => setOpen(false);

    const handleClick = () => { handleLandingModalClose() };

    const id = open ? 'simple-popover' : undefined;


    return (
        <Modal
            id={id}
            open={open}
            onClose={handleLandingModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: 'auto' }}
        >
            <Stack
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={0}
                sx={{
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden',
                    background: palette.background.paper
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid container
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {buttons.map(button => (
                            <Grid
                                item
                                xs={6}
                                md={6}
                                lg={3}
                                xl={3}
                                key={`landing-grid-item-${button.name}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"   
                            >
                                <Button
                                    sx={{
                                        height: "180px",
                                        width: "180px",
                                        border: 1,
                                        borderRadius: 12,
                                        marginBottom: '60px',
                                        color: button.clickable ? palette.secondary.light : 'inherit'
                                    }}
                                    id={button.name === 'news' ? 'landingPageClose' : `landing-btn-${button.name}`}
                                    onClick={button.clickable ? handleClick : undefined}
                                >
                                    <Stack
                                        direction='column'
                                        spacing={0}
                                        alignItems='center'
                                        justifyContent="center"
                                    >
                                        {button.icon}
                                        <Typography variant='h6'>
                                            {button.name}
                                        </Typography>
                                    </Stack>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Stack>
        </Modal>
    )
}
