import { PlayArrowRounded } from "@mui/icons-material"
import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useContext } from "react";
import { PlayerContext } from "../../../../../contexts/PlayerContext";
import { PlayerData, PlayerProps, PlayerStylePlaylist } from "../../../../../interfaces/player/player.interface";
import { FormattedMessage } from "react-intl";
import { getTopicsFromAudioList } from "../../../../../utils/topicUtils";
import { AppContext } from "../../../../../contexts/AppContext";
import { handleAudioSelect, handleTopicSelection } from "../../../playlistHandlers";
import { AudioDescriptorMainItem } from "../../../audioDescriptor/AudioDescriptorMainItem";
import { AudioDescriptorSimpleItem } from "../../../audioDescriptor/AudioDescriptorSimpleItem";
import { Topic } from '../../../../../interfaces/player/playerRequest.interface';

export const TopicPlaylist = ({ usePlayerTruncateValues = false }) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang, appModals } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const { topicModal } = appModals;
    const { setOpenTopicModal } = topicModal;

    const playerContext = useContext(PlayerContext);
    const { style, data, props } = playerContext;
    const { playerState } = props as PlayerProps;

    const { audioQueue } = data as PlayerData;
    const { visual, playlist } = style;

    const {
        primaryColor,
        secondaryColor,
        truncatePlayerDescription,
        truncatePlayerTitle,
    } = visual;

    const {
        showPlaylistAudioTitle,
        showPlaylistAudioDescription,
        showPlaylistAudioDate,
        showPlaylistAudioDuration,
        showPlaylistAudioNumber,
        showPlaylistAudioPublisher,
        showPlaylistAudioTopic,
        showPlaylistPlayIcon,
        showPlaylistImages,
        truncatePlaylistAudioTitle,
        truncatePlaylistAudioDescription,
    } = playlist as PlayerStylePlaylist;


    const largeScreen = useMediaQuery(theme.breakpoints.up("md"))

    const languageAudios = audioQueue.filter(
        audio => audio.language.substring(0, 2) === currentContextLanguage.substring(0, 2) && audio.visible
    );
    const topicList: Topic[] = getTopicsFromAudioList(languageAudios);

    const handleTopicChange = (topicId: number, topicLabel: string) => {
        window.scrollTo(0, 0);
        handleTopicSelection(
            playerContext,
            currentContextLanguage,
            setOpenTopicModal,
            topicList,
            topicId,
            topicLabel
        )
    }

    return (
        <Box
            px={0}
            py={1}
            sx={{
                pt: '15px'
            }}>
            {topicList.map((topic, index) => (
                <Stack
                    key={topic.id}
                    spacing={0}
                >

                    {/* display topic */}
                    {
                        languageAudios?.filter(audio => (
                            audio.visible &&
                            audio.topicId === topic.id &&
                            topic.id !== playerState.topicIndex
                        )).length > 0 &&
                        <Stack
                            direction="row"
                            sx={{
                                pl: 3,
                                paddingBottom: '6px',
                                mt: index > 0 ? 1 : 'inherit',
                                borderTop: index > 0 ? `1px solid ${palette.primary.light}` : 'inherit',
                            }}
                        >
                            {
                                topicList.length > 1 &&
                                <Button
                                    variant="outlined"
                                    size='small'
                                    id={`topic-button-${topic.id}`}
                                    sx={{
                                        borderColor: palette.primary.light,
                                        borderRadius: 2,
                                        color: palette.secondary.light,
                                        fontSize: '9pt',
                                        mt: 1,
                                        pb: 0,
                                        pt: '3px',
                                        px: '7px',
                                    }}
                                    onClick={() => handleTopicChange(topic.id, topic.label)}
                                >
                                    {
                                        topic.id > -1 &&
                                        <FormattedMessage id={`app.speech-topics-id-${topic.id}`} />
                                    }
                                </Button>
                            }
                        </Stack>
                    }
                    <Stack
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                    >
                        {
                            audioQueue?.map(audio => (
                                audio.visible &&
                                audio.topicId === topic.id &&
                                // if audio is first of the list for a given topic, display it as main one
                                audio.id === languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[0].id &&
                                <Box
                                    key={audio.id}
                                    component={'div'}
                                    width={largeScreen ? "68%" : "100%"}
                                    sx={{
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: palette.mode === 'dark' ? palette.grey[900] : palette.grey[100],
                                        }
                                    }}
                                    onClick={() => handleAudioSelect(audio.id, playerContext, currentContextLanguage, true)}
                                >
                                    <Stack direction='column'>
                                        {
                                            showPlaylistPlayIcon &&
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <IconButton
                                                    aria-label={`playlist-play-${audio.order}`}
                                                    disabled
                                                    sx={{ pl: 0 }}
                                                >
                                                    <PlayArrowRounded />
                                                </IconButton>
                                            </Box>
                                        }

                                        <AudioDescriptorMainItem
                                            audio={audio}
                                            visualStyle={{
                                                showTitle: showPlaylistAudioTitle,
                                                showDescription: showPlaylistAudioDescription,
                                                showDate: showPlaylistAudioDate,
                                                showDuration: showPlaylistAudioDuration,
                                                showPublisher: showPlaylistAudioPublisher,
                                                showTopic: showPlaylistAudioTopic,
                                                showImage: showPlaylistImages,
                                                truncateTitle: usePlayerTruncateValues ? truncatePlayerTitle : truncatePlaylistAudioTitle,
                                                truncateDescription: usePlayerTruncateValues ? truncatePlayerDescription : truncatePlaylistAudioDescription,
                                                imageSize: '200px',
                                                highlightSelectedAudio: true,
                                            }}
                                        />
                                    </Stack>
                                </Box>
                            ))}
                        <Stack
                            width={largeScreen ? "42%" : "100%"}
                            sx={{
                                pl: largeScreen ? 0 : '20px',
                                mr: '5px'
                            }}
                        >
                            {
                                languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id)).length > 1 &&

                                audioQueue?.map(audio => (
                                    audio.visible &&
                                    audio.topicId === topic.id &&
                                    // if audio is not the first of the topic list, display it smaller
                                    audio.id !== languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[0].id &&
                                    // !largeScreen &&
                                    (audio.id === languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[1].id ||
                                        audio.id === languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[2].id) &&
                                    <Box
                                        key={audio.id}
                                        component={'div'}
                                        sx={{
                                            borderRadius: 2,
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: palette.mode === 'dark' ? palette.grey[900] : palette.grey[100],
                                            }
                                        }}
                                        onClick={() => handleAudioSelect(audio.id, playerContext, currentContextLanguage, true)}
                                    >
                                        <Stack direction='column'>
                                            {
                                                showPlaylistPlayIcon &&
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <IconButton
                                                        aria-label={`playlist-play-${audio.order}`}
                                                        disabled
                                                        sx={{ pl: 0 }}
                                                    >
                                                        <PlayArrowRounded />
                                                    </IconButton>
                                                </Box>
                                            }

                                            <AudioDescriptorSimpleItem
                                                audio={audio}
                                                visualStyle={{
                                                    showTitle: showPlaylistAudioTitle,
                                                    showDescription: showPlaylistAudioDescription,
                                                    showDate: showPlaylistAudioDate,
                                                    showDuration: showPlaylistAudioDuration,
                                                    showPublisher: showPlaylistAudioPublisher,
                                                    showTopic: showPlaylistAudioTopic,
                                                    showImage: showPlaylistImages,
                                                    truncateTitle: usePlayerTruncateValues ? truncatePlayerTitle : truncatePlaylistAudioTitle,
                                                    truncateDescription: usePlayerTruncateValues ? truncatePlayerDescription : truncatePlaylistAudioDescription,
                                                    imageSize: '100px',
                                                    highlightSelectedAudio: true,
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                ))}
                        </Stack>

                    </Stack>
                    <Stack
                        sx={{
                            mt: largeScreen ? '15px' : '0px', ml: '20px'
                        }}
                    >
                        {
                            languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id)).length > 1 &&

                            audioQueue?.map(audio => (
                                audio.visible &&
                                audio.topicId === topic.id &&
                                // if audio is not the first of the topic list, display it smaller
                                audio.id !== languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[0].id &&
                                audio.id !== languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[1].id &&
                                audio.id !== languageAudios?.filter(audio => (audio.visible && audio.topicId === topic.id))[2].id &&
                                <Box
                                    key={audio.id}
                                    component={'div'}
                                    sx={{
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: palette.mode === 'dark' ? palette.grey[900] : palette.grey[100],
                                        }
                                    }}
                                    onClick={() => handleAudioSelect(audio.id, playerContext, currentContextLanguage, true)}
                                >
                                    <Stack direction='column'>
                                        {
                                            showPlaylistPlayIcon &&
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <IconButton
                                                    aria-label={`playlist-play-${audio.order}`}
                                                    disabled
                                                    sx={{ pl: 0 }}
                                                >
                                                    <PlayArrowRounded />
                                                </IconButton>
                                            </Box>
                                        }
                                        {
                                            showPlaylistAudioNumber &&
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                pr={1}
                                            >
                                                <Typography
                                                    aria-label={`playlist-order-${audio.order}`}
                                                    color={audio.selected ? primaryColor : secondaryColor}
                                                    fontWeight={600}
                                                    variant="body1"
                                                >
                                                    {audio.order}
                                                </Typography>
                                            </Box>
                                        }

                                        <AudioDescriptorSimpleItem
                                            audio={audio}
                                            visualStyle={{
                                                showTitle: showPlaylistAudioTitle,
                                                showDescription: showPlaylistAudioDescription,
                                                showDate: showPlaylistAudioDate,
                                                showDuration: showPlaylistAudioDuration,
                                                showPublisher: showPlaylistAudioPublisher,
                                                showTopic: showPlaylistAudioTopic,
                                                showImage: showPlaylistImages,
                                                truncateTitle: usePlayerTruncateValues ? truncatePlayerTitle : truncatePlaylistAudioTitle,
                                                truncateDescription: usePlayerTruncateValues ? truncatePlayerDescription : truncatePlaylistAudioDescription,
                                                imageSize: '100px',
                                                highlightSelectedAudio: true,
                                            }}
                                        />
                                    </Stack>
                                </Box>
                            ))
                        }
                    </Stack>
                </Stack>
            ))
            }
        </Box >
    )
}