import { useContext } from 'react';

// modules
import { FormattedMessage } from "react-intl";

// contexts
import { AppContext } from "../../contexts/AppContext";

// mui
import { useTheme } from '@mui/material/styles';
import {
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

export const SpeechFeedback = () => {
    const theme = useTheme();
    const { palette } = theme;

    const { appRecording, audioGuide } = useContext(AppContext);
    const { isRecording, voiceCommand } = appRecording;
    const { audioGuideActivated } = audioGuide;

    return (
        <>
        {audioGuideActivated &&
            <Paper
                elevation={3}
                sx={{
                    alignItems: "center",
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    m: 2,
                    p: 2,
                    verticalAlign: "middle",
                    minWidth: '70%'
                }}
            >
                <Stack
                    direction={'column'}
                    spacing={1}
                >
                    <Typography
                        variant="caption"
                        sx={{ fontStyle: 'italic' }}
                        color={palette.text.secondary}
                    >
                        <FormattedMessage id={isRecording ? "app.speech-hint-playlist" : "app.speech-feedback-disabled"} />
                    </Typography>
                    {
                        voiceCommand.command.length > 0 &&
                        <Typography
                            variant="body1"
                            color={voiceCommand.isMatched ? palette.success.light : palette.error.light}
                            sx={{
                                fontWeight: 'bold',
                                fontStyle: 'italic',
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                verticalAlign: "middle",
                            }}
                        >
                            {
                                voiceCommand.isMatched
                                    ? <CheckCircleIcon />
                                    : voiceCommand.command.length > 0 && <WarningIcon />
                            }
                            &nbsp;
                            {
                                voiceCommand.command
                            }
                        </Typography>
                    }
                </Stack>
                </Paper> 
            }
            </>
        )
    }
