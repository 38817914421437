import { useLocation, useSearchParams } from "react-router-dom"

export const useCurrentPath = () => {
    const location = useLocation();
    let path = location.pathname;
    const [URLSearchParams] = useSearchParams();

    const type = URLSearchParams.get("type");
    if (type && type?.length > 0) path += "?type=" + type

    return [path];
}