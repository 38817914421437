
import { useContext, useEffect, useRef, useState } from 'react';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, ListSubheader, Stack, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { PlayerContext } from '../../contexts/PlayerContext';
import { PlayerData } from '../../interfaces/player/player.interface';
import { handleSeekTo } from '../player/playerHandlers';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../utils/formatDate';
import { AppContext } from '../../contexts/AppContext';
import { formatDuration } from '../player/odiaPlayerHandlers';
import { Headset } from '@mui/icons-material';
// img
import logoOdia from "../../img/logo-odia-original.png";
import logoOdiaLight from "../../img/logo-odia-original-light.png";

export const AudioTextContent = () => {
    const theme = useTheme();
    const { palette } = theme;
    const audioTextContentStackRef = useRef<HTMLDivElement>(null);

    const { appLang } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;
    const playerContext = useContext(PlayerContext);
    const { style, data, props } = playerContext;
    const { visual } = style;
    const {
        backgroundColor,
        boxShadow,
        secondaryColor,
        showOdiaLabelIcon,
        linkToWebsite,
    } = visual;
    const bgColor =
        palette.mode !== "dark" ? backgroundColor : palette.background.paper;
    const { currentAudio } = data as PlayerData;
    const [playingSectionId, setPlayingSectionId] = useState(0);
    const [userSectionChange, setUserSectionChanged] = useState(false);

    const largeScreen = useMediaQuery(theme.breakpoints.up("md"))
    const xsScreen = useMediaQuery(theme.breakpoints.down("sm"))


    useEffect(() => {
        let duration = playerContext.props?.playerState.duration ? playerContext.props?.playerState.duration * 1000 : 0;
        let currentSectionId = 0;
        let audioPlayed = playerContext.props?.playerState.playedSeconds ? playerContext.props?.playerState.playedSeconds * 1000 : 0;

        if (playerContext.props?.position && duration) {
            for (let section of currentAudio.articleSections) {
                if (audioPlayed >= section.startTimestampMillis &&
                    audioPlayed < section.startTimestampMillis + section.audioDurationMillis) {
                    currentSectionId = section.order;
                    break;
                }
            }

            setPlayingSectionId(currentSectionId);

            if (audioTextContentStackRef && audioTextContentStackRef.current) {

                // if user changes section manually from the visible text, don't change the actual scroll position
                if (userSectionChange) {

                    setUserSectionChanged(false);

                }
                // if user comes back to the text display from the playlist view, set scroll to make the text being read (highlighted) visible
                else if (props && !props.playerState.isArticleTextDisplayed) {

                    props?.setPlayerState((state) => ({
                        ...state,
                        isArticleTextDisplayed: true
                    }))

                }
            }
        }
        // eslint-disable-next-line
    }, [playerContext.props?.position])


    const handleSectionChange = (sectionId: number) => {

        if (props?.playerRef) {
            handleSeekTo(0, props.playerRef, currentAudio.articleSections[sectionId].startTimestampMillis / 1000);

            setUserSectionChanged(true);
        }

        props?.setPlayerState((state) => ({
            ...state,
            playing: true
        }))
    };

    const handleHideAudioContent = () => {
        if (props) {
            props.setShowAudioTextContent(false);

            props?.setPlayerState((state) => ({
                ...state,
                isArticleTextDisplayed: false
            }))
        }
    }

    return (
        <Stack
            sx={{
                height: "77.5vh"
            }}
        >
            <ListSubheader
                sx={{
                    backgroundColor: bgColor,
                    boxShadow,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ justifyContent: "space-between" }}
                >
                    <Button
                        size='small'
                        onClick={handleHideAudioContent}
                        sx={{
                            color: palette.secondary.light,
                            fontSize: '9pt',
                            pb: 0,
                            px: '7px',
                            ml: '8px',
                            mb: '3px'
                        }}>
                        <KeyboardArrowDownIcon
                            style={{ color: palette.secondary.light, marginBottom: '1px' }}
                        />
                        <Typography
                            aria-label="logo text"
                            variant="caption"
                        >
                            <FormattedMessage id="app.playlist-hide-audio-text-content" />
                        </Typography>
                    </Button>

                    {showOdiaLabelIcon && (
                        <Box
                            p={0}
                            sx={{
                                display: { xs: "none", sm: "inherit" },
                                height: "15px"
                            }}
                        >
                            {linkToWebsite ? (
                                <a
                                    href={`https://www.odialab.com/${lang.current === "fr-FR" ? "fr" : "en"
                                        }/`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ display: "block" }}
                                >
                                    <img
                                        aria-label="logo icon"
                                        src={
                                            palette.mode === "dark" ? logoOdiaLight : logoOdia
                                        }
                                        alt="logo"
                                        style={{
                                            height: "15px",
                                            display: "block",
                                            marginTop: "2px",
                                        }}
                                    />
                                </a>
                            ) : (
                                <img
                                    aria-label="logo icon"
                                    src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                                    alt="logo"
                                    style={{ height: "15px", marginTop: "2px" }}
                                />
                            )}
                        </Box>
                    )}

                </Stack>
                <Box sx={{
                    height: 28
                }}>
                    <Typography
                        color={secondaryColor}
                        noWrap
                        fontWeight='bold'
                        sx={{
                            m: 0,
                            ml: "20px",
                            mr: "15px",
                            overflow: "hidden",
                        }}
                    >
                        {currentAudio.publisherTitle}
                    </Typography>
                </Box>
                <Stack
                    direction="row"
                    alignItems="center"
                >

                </Stack>
            </ListSubheader >
            <Stack
                ref={audioTextContentStackRef}
                sx={{
                    maxHeight: "77vh",
                    pl: 1,
                    pr: 1,
                    scrollbarWidth: "thin",
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        height: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)',
                        ml: 1,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: "5px",
                    },
                }}
            >
                {currentAudio.articleSections && currentAudio.articleSections.length > 0 &&
                    currentAudio.articleSections.map(section => (
                        <Stack
                            sx={{
                                '&:hover': {
                                    background: palette.mode === 'dark' ? palette.grey[900] : palette.grey[100],
                                },
                                borderRadius: 2
                            }}
                        >
                            {
                                section.type === "TITLE" &&

                                <Stack
                                    direction='row'
                                    sx={{ ml: "15px", mt: "20px", mr: "15px", mb: "15px" }}
                                >
                                    {currentAudio.imageUrl &&
                                        <Box
                                            id={"section-article-image"}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="align"
                                            sx={{
                                                height: largeScreen ? "280px" : xsScreen ? "100px" : "200px",
                                                width: largeScreen ? "280px" : xsScreen ? "100px" : "200px"
                                            }}
                                        >
                                            <img
                                                id="article-image"
                                                alt="Article"
                                                src={currentAudio.imageUrl}
                                                style={{
                                                    borderRadius: 6,
                                                    height: largeScreen ? "280px" : xsScreen ? "100px" : "200px",
                                                    width: largeScreen ? "280px" : xsScreen ? "100px" : "200px",
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </Box>
                                    }
                                    <Stack>
                                        <Box
                                            sx={{ ml: "15px", mb: "8px", mr: "15px" }}
                                        >
                                            <Button
                                                variant="outlined"
                                                size='small'
                                                id={`topic-button-${currentAudio.topicId}`}
                                                sx={{
                                                    borderColor: palette.primary.light,
                                                    borderRadius: 2,
                                                    color: palette.secondary.light,
                                                    fontSize: '9pt',
                                                    pb: 0,
                                                    px: '7px',
                                                }}
                                            >
                                                <FormattedMessage id={`app.speech-topics-id-${currentAudio.topicId}`} />
                                            </Button>
                                        </Box>
                                        <Typography color={(section.order === playingSectionId) ? palette.secondary.light : secondaryColor}
                                            sx={{
                                                cursor: 'pointer',
                                                textDecoration: (section.order === playingSectionId) ? 'underline' : 'None'
                                            }}
                                        >
                                            <Box
                                                id={"section-" + section.order.toString()}
                                                fontWeight='bold'
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="align"
                                                onClick={() => handleSectionChange(section.order)}
                                                sx={{ ml: "15px", mb: "8px", mr: "15px" }}
                                            >{section.text}
                                            </Box>
                                        </Typography>
                                        {
                                            !xsScreen &&
                                            <Typography
                                                color={secondaryColor}
                                            >
                                                <Box
                                                    id={"section-publisherLabel-" + currentAudio.publisherLabel}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="align"

                                                    sx={{ ml: "13px", mt: "0px", mb: "3px" }}
                                                >{currentAudio.publisherLabel}
                                                </Box>
                                            </Typography>
                                        }
                                        {
                                            !xsScreen &&
                                            <Typography color={secondaryColor}>
                                                <Box
                                                    id={"section-publisherPublicationDate-" + currentAudio.publisherPublicationDate}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="align"
                                                    sx={{ ml: "15px", mt: "0px", mr: "15px" }}
                                                >{formatDate(currentAudio.publisherPublicationDate, currentContextLanguage)}
                                                </Box>
                                            </Typography>
                                        }
                                        {
                                            !xsScreen && currentAudio.author &&
                                            <Typography color={secondaryColor}>
                                                <Box
                                                    id={"section-author-" + currentAudio.author}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="align"
                                                    sx={{ ml: "15px", mt: "8px", mb: "8px", mr: "15px" }}
                                                >{currentAudio.author}
                                                </Box>
                                            </Typography>
                                        }
                                        {
                                            !xsScreen && props && props?.playerState.duration > 0 &&
                                            <Typography color={secondaryColor}>
                                                <Box
                                                    id={"section-duration"}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="align"
                                                    sx={{ ml: "15px", mt: "8px", mb: "8px", mr: "15px" }}
                                                ><Headset htmlColor="inherit" sx={{ mr: 1 }} />{formatDuration(props.playerState.duration)}
                                                </Box>
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            }
                            {
                                section.type === "SUBHEADING" &&
                                <Typography
                                    color={(section.order === playingSectionId) ? palette.secondary.light : secondaryColor}
                                    sx={{
                                        cursor: 'pointer',
                                        textDecoration: (section.order === playingSectionId) ? 'underline' : 'None'
                                    }}
                                >
                                    <Box
                                        id={"section-" + section.order.toString()}
                                        fontWeight='bold'
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="align"
                                        onClick={() => handleSectionChange(section.order)}
                                        sx={{ ml: "15px", mt: "8px", mb: "8px", mr: "15px" }}
                                    >{section.text}
                                    </Box>
                                </Typography>
                            }
                            {
                                section.type === "CONTENT" &&
                                <Typography
                                    color={(section.order === playingSectionId) ? palette.secondary.light : secondaryColor}
                                    sx={{
                                        cursor: 'pointer',
                                        fontWeight: (section.order === playingSectionId) ? 'medium' : 'regular',
                                        textDecoration: (section.order === playingSectionId) ? 'underline' : 'None'
                                    }}
                                >
                                    <Box
                                        id={"section-" + section.order.toString()}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="align"
                                        onClick={() => handleSectionChange(section.order)}
                                        sx={{ ml: "15px", mb: "2px", mt: "2px", mr: "15px" }}
                                    >{section.text}
                                    </Box>
                                </Typography>
                            }
                        </Stack>
                    )
                    )
                }
            </Stack >
        </Stack >
    )

}