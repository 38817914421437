// utils
import { getCommandActionType } from "../../speech/playerCommandsMapping";

interface MatchCommand {
    command: string,
    commandType: string,
    isMatched: boolean
}

export const matchCommand = (command: string, commandList: string[], language: string): MatchCommand => {
    // save all matches by regex
    const matchedCommands = [];
    for (const commandItem of commandList) {
        const expr = new RegExp(commandItem);
        if (command.match(expr)) {
            matchedCommands.push(commandItem);
        };
    }

    // no matched commands
    if (matchedCommands.length === 0) {
        return {
            command,
            commandType: '',
            isMatched: false
        };
    } else {
        // only one match, return it
        if (matchedCommands.length === 1) {
            return {
                command: matchedCommands[0],
                commandType: getCommandActionType(matchedCommands[0], language),
                isMatched: true
            };
        } else {
            // several matches: topic selection is prioritary
            // i.e. "play technology" will match "play" and "technology"
            // in that case we want to prioritize "technology"

            // find the type of every matched command
            const commandList: MatchCommand[] = []
            for (const matchedCommand of matchedCommands) {
                commandList.push({
                    command: matchedCommand,
                    commandType: getCommandActionType(matchedCommand, language),
                    isMatched: true
                })
            }

            const topicSelectionMatches = commandList.filter(c => c.commandType === 'topicSelect')

            if (topicSelectionMatches.length > 0) {
                return topicSelectionMatches[0];
            } else {
                return commandList[0];
            }
        }
    }
}
