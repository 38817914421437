import { useContext, useEffect, useState } from "react";

// modules
import { FormattedMessage } from "react-intl";

// components
import { PlaylistQueueList } from "./playlistQueue/playlistQueueList/PlaylistQueueList";
import { TopicSelector } from "../topicSelector/TopicSelector";
import { AudioTextContent } from "../../audioTextContent/AudioTextContent";
import { PlaylistQueuePortal } from "./playlistQueue/playlistQueuePortal/PlaylistQueuePortal";

// contexts
import { AppContext } from "../../../contexts/AppContext";
import { PlayerContext } from "../../../contexts/PlayerContext";

// interfaces
import {
  PlayerData,
  PlayerStylePlaylist,
} from "../../../interfaces/player/player.interface";
import { MobileOrientation } from "../../../interfaces/playlist/mobile.interface";
import { PlaylistPanelView } from "../../../interfaces/playlist/playlistPanelView.interface";

// mui
import { useTheme } from "@mui/material/styles";
import { Box, ListSubheader, Stack, Typography } from "@mui/material";

// img
import logoOdia from "../../../img/logo-odia-original.png";
import logoOdiaLight from "../../../img/logo-odia-original-light.png";

// styles
import "../../styles/truncate-text.css";

// data
import { getTopicsFromAudioList } from "../../../utils/topicUtils";
import { PlaylistQueueCovers } from "./playlistQueue/playlistQueueCovers/PlaylistQueueCovers";
import { ToggleViewButtons } from "./toggleViewButtons/ToggleViewButtons";
import { PlaylistQueueListMobile } from "./playlistQueue/playlistQueueListMobile/PlaylistQueueListMobile";

interface Props {
  view?: PlaylistPanelView;
  mobileViewOrientation?: MobileOrientation;
}

export const PlaylistPanel = ({
  view = "list",
  mobileViewOrientation = "portrait",
}: Props) => {
  const theme = useTheme();
  const { palette } = theme;

  const { appLang } = useContext(AppContext);
  const { lang } = appLang;

  const playerContext = useContext(PlayerContext);
  const { style, data, props } = playerContext;
  const { audioQueue } = data as PlayerData;
  const { visual, playlist } = style;

  const [playlistView, setPlaylistView] = useState(view);
  const [showAudioContent, setShowAudioContent] = useState(props ? props.showAudioTextContent : false);

  const {
    backgroundColor,
    boxShadow,
    showOdiaLabel,
    showOdiaLabelIcon,
    linkToWebsite,
    displayAudioContent
  } = visual;

  const {
    showPlaylistHeader,
    showPlaylistToggleViewButtons,
    showPlaylistTopicSelector,
  } = playlist as PlayerStylePlaylist;

  const topics = getTopicsFromAudioList(audioQueue);

  const bgColor =
    palette.mode !== "dark" ? backgroundColor : palette.background.paper;

  const commonStyleProps = {
    background: bgColor,
    borderRadius: 4,
    boxShadow,
    overflowX: "hidden",
    overflowY: "auto",
  };

  useEffect(() => {
    if (props) {
      setShowAudioContent(props?.showAudioTextContent);
    }
  }, [props, props?.showAudioTextContent]);

  return (
    <Box
      maxWidth="md"
      sx={
        mobileViewOrientation === "portrait"
          ? {
            ...commonStyleProps,
            maxHeight: showPlaylistHeader ? "65vh" : playlistView === "portal" ? "78vh" : "74vh",
            maxWidth: playlistView === "portal" ? "md" : "96vw",
            minHeight: 150,
            ml: "auto",
            mr: "auto",
            mt: 2
          }
          : {
            ...commonStyleProps,
            maxHeight: showPlaylistHeader ? "95vh" : playlistView === "portal" ? "78vh" : "98vh",
            maxWidth: playlistView === "portal" ? "md" : "100vw",
            mt: 1
          }
      }
    >
      <Stack
        direction="column"
        spacing="0"
      >
        {showAudioContent &&
          <AudioTextContent />
        }
        {!showAudioContent &&
          <ListSubheader
            sx={{
              backgroundColor: bgColor,
              boxShadow,
            }}
          >
            {(showOdiaLabel ||
              showOdiaLabelIcon ||
              showPlaylistTopicSelector) && (
                <Box
                  p={1}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    {showPlaylistTopicSelector && topics.length > 1 && (
                      <TopicSelector topics={topics} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {showOdiaLabel && (
                      <Typography
                        aria-label="logo text"
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          display: { xs: "none", sm: "inherit" },
                          marginTop: "2px",
                        }}
                      >
                        <FormattedMessage id="app.player-poweredBy-label" />
                        &nbsp;
                      </Typography>
                    )}
                    {showOdiaLabelIcon && (
                      <Box
                        p={0}
                        sx={{
                          display: { xs: "none", sm: "inherit" },
                          height: "15px",
                        }}
                      >
                        {linkToWebsite ? (
                          <a
                            href={`https://www.odialab.com/${lang.current === "fr-FR" ? "fr" : "en"
                              }/`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ display: "block" }}
                          >
                            <img
                              aria-label="logo icon"
                              src={
                                palette.mode === "dark" ? logoOdiaLight : logoOdia
                              }
                              alt="logo"
                              style={{
                                height: "15px",
                                display: "block",
                                marginTop: "2px",
                              }}
                            />
                          </a>
                        ) : (
                          <img
                            aria-label="logo icon"
                            src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                            alt="logo"
                            style={{ height: "15px", marginTop: "2px" }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  {!displayAudioContent && showPlaylistToggleViewButtons && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ToggleViewButtons
                        playlistView={playlistView}
                        setPlaylistView={setPlaylistView}
                      />
                    </Box>
                  )}
                </Box>
              )}
          </ListSubheader>
        }
        <Box

          id="playlist-panel-queue-box"
          sx={{
            maxHeight: showPlaylistHeader ? "60vh" : playlistView === "portal" ? "77vh" : "68vh",
            pl: 1,
            pr: 1,
            scrollbarWidth: "thin",
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)',
              ml: 1,
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: "5px",
            },
          }}

        >
          {!showAudioContent && playlistView === "list" && (
            <PlaylistQueueList usePlayerTruncateValues={true} />
          )}
          {!showAudioContent && playlistView === "listMobile" && <PlaylistQueueListMobile />}
          {!showAudioContent && playlistView === "covers" && <PlaylistQueueCovers />}
          {!showAudioContent && playlistView === "portal" && <PlaylistQueuePortal />}
        </Box>
      </Stack>
    </Box >
  );
};
