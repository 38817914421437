import { useContext } from 'react';

// contexts
import { PlayerContext } from '../../../../../contexts/PlayerContext';

// interfaces
import { PlayerProps } from '../../../../../interfaces/player/player.interface';

// actions
import { AllTopicsPlaylist } from './AllTopicsPlaylist';
import { TopicPlaylist } from './TopicPlaylist';
import { Box } from '@mui/material';


export const PlaylistQueuePortal = () => {

    const playerContext = useContext(PlayerContext);
    const { props } = playerContext;
    const { playerState } = props as PlayerProps;


    return (

        <Box
            maxHeight={'73vh'}
        >
            {
                playerState.topicIndex === 0 &&
                <AllTopicsPlaylist />
            }
            {
                playerState.topicIndex !== 0 &&
                <TopicPlaylist />
            }
        </Box >
    )
}