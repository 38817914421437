import { useContext } from 'react';

// modules
import { FormattedMessage } from 'react-intl';

// contexts
import { PlayerContext } from '../../../contexts/PlayerContext';
import { AppContext } from '../../../contexts/AppContext';

// mui
import { Box, Link, Stack, Typography } from '@mui/material';
import { PlayerAudio } from '../../../interfaces/player/player.interface';

// utils
import { formatDate } from '../../../utils/formatDate';

// interfaces
import { MobileOrientation } from '../../../interfaces/playlist/mobile.interface';

// img
import equalizer from './img/equalizer.gif';

interface Props {
    audio: PlayerAudio,
    visualStyle: AudioDescriptorMobileVisualStyle,
    view?: string,
    mobileViewOrientation?: MobileOrientation,
    isPlayedAudio?: boolean,
}
interface AudioDescriptorMobileVisualStyle {
    highlightSelectedAudio: boolean,
    imageSize: string,
    showDate: boolean,
    showImage: boolean,
    showPublisher: boolean,
    showTitle: boolean,
    showTopic: boolean,
    addPublisherLink: boolean
}


export const AudioDescriptorMobile = ({
    audio, visualStyle, view, mobileViewOrientation = 'portrait', isPlayedAudio = false
}: Props) => {
    const { appLang } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const playerContext = useContext(PlayerContext);
    const { type, style } = playerContext;
    const { visual } = style;

    const { primaryColor, secondaryColor, } = visual;

    const {
        publisherTitle,
        imageUrl,
        publisherLabel,
        publisherWebsiteUrl,
        publisherPublicationDate,
        topicId,
        selected
    } = audio;

    const {
        highlightSelectedAudio,
        imageSize,
        showDate,
        showImage,
        showPublisher,
        showTitle,
        showTopic,
        addPublisherLink
    } = visualStyle;

    const applyAudioSelected = isPlayedAudio ? true : selected;

    return (
        <Stack
            direction='row'
            sx={
                mobileViewOrientation === 'portrait'
                    ? {
                        display: 'flex',
                        alignItems: 'top',
                        pt: '5px',
                        pl: type.design === 'large' ? '8px' : '20px'
                    }
                    : {
                        display: 'flex',
                        alignItems: 'top',
                        pt: 2,
                        pl: 0.2
                    }
            }
        >
            {
                view !== 'portal' &&
                showImage &&
                imageUrl &&
                imageUrl !== '' &&
                <Box
                    display="flex"
                    alignItems={type.design === "large" ? "top" : "center"}
                    justifyContent="center"
                    pr={mobileViewOrientation === 'portrait' ? 2 : 1}
                >
                    <img
                        id="article-image"
                        src={imageUrl}
                        alt={publisherTitle}
                        style={{
                            border:
                                selected && highlightSelectedAudio
                                    ? `2px solid ${primaryColor}`
                                    : `2px solid transparent`,
                            borderRadius: 6,
                            height: imageSize,
                            objectFit: 'cover',
                            width: imageSize,
                        }}
                    />
                    {
                        audio.selected && !isPlayedAudio &&
                        <img
                            aria-label='audio-playing-gif'
                            src={equalizer}
                            alt='playing'
                            style={{
                                height: '50px',
                                marginLeft: -75,
                                marginRight: 25
                            }}
                        />
                    }
                </Box>
            }
            <Stack
                direction='column'
                spacing={0.2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {
                    view !== 'portal' &&
                    showTitle &&
                    <Typography
                        aria-label='label title'
                        color={applyAudioSelected ? primaryColor : secondaryColor}
                        variant={type.design === "large" ? "caption" : "subtitle2"}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }}
                    >
                        <b>{publisherTitle}</b>
                    </Typography>
                }
                {
                    view !== 'portal' &&
                    showPublisher &&
                    <Stack direction='row'>
                        <Typography
                            aria-label='playlistAudioPublisher'
                            color={secondaryColor}
                            variant="body2"
                        >
                            {
                                (addPublisherLink && publisherWebsiteUrl && publisherWebsiteUrl !== '') ?
                                    <Link href={publisherWebsiteUrl} color={secondaryColor}><b>{publisherLabel}</b></Link> : publisherLabel
                            }
                        </Typography>
                        {
                            showDate &&
                            <Typography
                                aria-label='playlistAudioDate'
                                color={secondaryColor}
                                variant="body2"
                            >
                                &nbsp;&bull;&nbsp;
                                {formatDate(publisherPublicationDate, currentContextLanguage)}
                            </Typography>
                        }
                    </Stack>
                }
                {
                    view !== 'portal' &&
                    showTopic &&
                    <Box>
                        <Typography
                            aria-label='playlistAudioTopic'
                            color={secondaryColor}
                            variant="caption"
                        >
                            <FormattedMessage id={`app.speech-topics-id-${topicId}`} />
                        </Typography>
                    </Box>
                }
            </Stack>
        </Stack>
    )
}
