import { useContext, useEffect } from 'react';

// modules
import { useLoaderData } from 'react-router-dom';

// components
import { OdiaPlaylist } from '../playlist/OdiaPlaylist';
import { TopBar } from '../topBar/TopBar';

// context
import { AppContext } from '../../contexts/AppContext';

// interfaces
import { PlayerRequestValidation } from '../../interfaces/player/playerRequest.interface';
import { PlayerData, PlayerStyle, PlayerStylePlaylist } from '../../interfaces/player/player.interface';

// types
import { playlistTypes } from '../../types/playlistTypes';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Stack, Typography } from '@mui/material';


export const DemoPlaylistPage = () => {
    const theme = useTheme();
    const { palette } = theme;

    const validationResult = useLoaderData() as PlayerRequestValidation;

    const {
        language: requestLanguage,
        playerData
    } = validationResult;
    const { audioQueue } = playerData as PlayerData;

    const { appLang } = useContext(AppContext);
    const { lang, setLang } = appLang;


    useEffect(() => {
        if (lang.current !== requestLanguage) {
            setLang({
                ...lang,
                current: requestLanguage
            })
        }
    }, [lang, requestLanguage, setLang])


    // playlist simple default
    const playlistSimple = playlistTypes.filter(p =>
        p.type.mode === 'playlist' && p.type.design === 'simple'
    );
    const playlistSimpleType = playlistSimple[0].type;
    const playlistSimpleStyle: PlayerStyle = { ...playlistSimple[0].style }
    const playlistStylePlaylist = playlistSimple[0].style.playlist as PlayerStylePlaylist;

    // playlist simple custom
    const playlistSimpleCustom: PlayerStyle = {
        ...playlistSimple[0].style,
        buttons: {
            ...playlistSimple[0].style.buttons,
            showPlayerForwardButton: false,
            showPlayerRewindButton: false,
            showPlayerSpeedButton: false,
        },
        visual: {
            ...playlistSimple[0].style.visual,
            primaryColor: '#1976d2',
            backgroundColor: 'white',
            showOdiaLabel: false,
            showPlayerTitle: false,
            showPlayerDescription: false,
        },
        playlist: {
            ...playlistStylePlaylist,
            showPlaylistAudioDescription: false,
            showPlaylistPlayIcon: false,
            showPlaylistAudioPublisher: false,
            showPlaylistAudioDuration: false,
            showPlaylistTopicSelector: false,
            showPlaylistImages: false
        }
    }

    // playlist compact default
    const playlistCompact = playlistTypes.filter(p =>
        p.type.mode === 'playlist' && p.type.design === 'compact'
    );
    const playlistCompactType = playlistCompact[0].type;
    const playlistCompactStyle: PlayerStyle = { ...playlistCompact[0].style }


    return (
        <>
            <CssBaseline />
            <TopBar visualStyle={{ showHomeButton: false, showOdiaLabel: false, linkToWebsite: false }} />
            <Box sx={{ p: theme.spacing(1) }}>
                <Stack direction={'column'} spacing={2}>
                    <Typography
                        aria-label='simple-playlist-header'
                        variant='h4'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(4) }}
                    >
                        Simple playlist
                    </Typography>
                    <OdiaPlaylist
                        playerType={playlistSimpleType}
                        playerStyle={playlistSimpleStyle}
                        audioQueue={audioQueue}
                    />

                    <Typography
                        variant='h6'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(1) }}
                    >
                        Simple playlist customizations
                    </Typography>
                    <OdiaPlaylist
                        playerType={playlistSimpleType}
                        playerStyle={playlistSimpleCustom}
                        audioQueue={audioQueue}
                    />

                    <Typography
                        aria-label='compact-playlist-header'
                        variant='h4'
                        color={palette.info.main}
                        sx={{ pt: theme.spacing(4) }}
                    >
                        Compact playlist
                    </Typography>
                    <OdiaPlaylist
                        playerType={playlistCompactType}
                        playerStyle={playlistCompactStyle}
                        audioQueue={audioQueue}
                    />
                </Stack>
            </Box>
        </>
    )
}
