
// mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, CssBaseline, IconButton, Slider, Stack, Tooltip } from "@mui/material";
import { Headset, PlayArrowRounded, Refresh } from '@mui/icons-material';

// img
import logoOdia from "../../../img/logo-odia-original.png";
import logoOdiaLight from "../../../img/logo-odia-original-light.png";

import { defaultVisual } from "../../../types/default/defaultProps";


const Widget = styled("div")(() => ({
    padding: 1,
    borderRadius: 16,
    width: "98vw",
    maxWidth: "100%",
    margin: "auto",
    position: "relative",
    zIndex: 1,
}));


export const DisabledPlayer = () => {
    const theme = useTheme();
    const { palette } = theme;

    const bgColor =
        palette.mode !== "dark" ? defaultVisual.backgroundColor : palette.background.paper;

    return (
        <>
            <CssBaseline />
            <Box
                aria-label="disabledPlayer"
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    m: 0,
                    overflow: "hidden",
                    borderRadius: 4,
                    boxShadow: defaultVisual.boxShadow,
                    backgroundColor: bgColor
                }}
            >
                <Widget>
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        zIndex={999}
                    >
                        <Tooltip
                            placement="right"
                            title="Try to reload the audio"
                        >
                            <Button
                                onClick={() => window.location.reload()}
                                variant="contained"
                                sx={{ borderRadius: 100 }}
                            >
                                <Refresh htmlColor='#1976d2' sx={{ fontSize: '2rem' }} />
                            </Button>
                        </Tooltip>
                    </Box>

                    <Stack
                        spacing={0}
                        direction="column"
                        sx={{ m: 0, px: 0, width: 1, minWidth: 0, filter: 'blur(1px)' }}
                        alignItems="center"
                    >
                        <Stack
                            spacing={1}
                            direction="row"
                            sx={{ mb: 0, px: 0, width: 1, minWidth: 0 }}
                            alignItems="center"
                        >
                            {/* buttons */}
                            <Headset htmlColor="inherit" sx={{ opacity: 0.3, mr: 1 }} />

                            <IconButton disabled>
                                <PlayArrowRounded sx={{ fontSize: '2rem' }} />
                            </IconButton>

                            <Slider
                                disabled
                                size="small"
                                value={0}
                                min={0}
                                max={0}
                                step={1}
                                sx={{
                                    color: defaultVisual.primaryColor,
                                    height: 4,
                                    "& .MuiSlider-thumb": {
                                        width: 8,
                                        height: 8,
                                        transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                                        "&:before": {
                                            boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                                        },
                                        "&:hover, &.Mui-focusVisible": {
                                            boxShadow: `0px 0px 0px 8px ${theme.palette.mode === "dark"
                                                ? "rgb(255 255 255 / 16%)"
                                                : "rgb(0 0 0 / 16%)"
                                                }`,
                                        },
                                        "&.Mui-active": {
                                            width: 20,
                                            height: 20,
                                        },
                                    },
                                    "& .MuiSlider-rail": {
                                        opacity: 0.28,
                                    },
                                }}
                            />

                            <Box sx={{ display: "flex", alignItems: "center", filter: 'grayscale(100%)' }}>
                                <a
                                    href='https://www.odialab.com/en/'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        aria-label="logo icon"
                                        src={palette.mode === "dark" ? logoOdiaLight : logoOdia}
                                        alt="logo"
                                        style={{ height: "15px" }}
                                    />
                                </a>

                            </Box>
                        </Stack>
                    </Stack>
                </Widget>
            </Box>
        </>

    );
};
