import { useContext, useState, useEffect } from 'react';

// modules
import { FormattedMessage } from "react-intl";

// contexts
import { AppContext } from '../../../contexts/AppContext';
import { PlayerContext } from '../../../contexts/PlayerContext';

// interfaces
import {
    PlayerData,
    PlayerProps,
    PlayerState,
} from '../../../interfaces/player/player.interface';

// mui
import {
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

// utils
import { handleTopicSelection } from '../playlistHandlers';
import { Topic } from '../../../interfaces/player/playerRequest.interface';
import { getTopicsFromAudioList } from '../../../utils/topicUtils';


interface Props {
    topics: Topic[]
}


export const TopicSelector = ({ topics }: Props) => {
    const theme = useTheme();
    const { palette } = theme;
    const { appLang, appTopics, appModals } = useContext(AppContext);

    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const { topics: topicList } = appTopics;

    const { topicModal } = appModals;
    const { openTopicModal, setOpenTopicModal } = topicModal;


    const playerContext = useContext(PlayerContext);
    const { data, props } = playerContext;
    const { audioQueue } = data as PlayerData;

    const {
        playerState,
        setPlayerState
    } = props as PlayerProps;


    if (!topics.some(topic => topic.id === 0)) {
        const newTopic: Topic = { id: 0, code: '', label: '', thumbnailUrl: '' }; // Create a new Topic object with id 0
        topics.push(newTopic); // Push the new Topic object to the array
        topics.sort();
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleTopicButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenTopicModal(true);
    };

    const handleTopicSelectorClose = () => {
        setAnchorEl(null);
        setOpenTopicModal(false);
    };

    const languageAudios = audioQueue?.filter(
        audio => audio.language.substring(0, 2) === currentContextLanguage.substring(0, 2)
    );

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, topicId: number, topicLabel: string = "") => {
        setAnchorEl(null);
        setOpenTopicModal(false);
        handleTopicSelection(
            playerContext,
            currentContextLanguage,
            setOpenTopicModal,
            getTopicsFromAudioList(languageAudios),
            topicId,
            topicLabel
        );
    }

    // const open = Boolean(anchorEl);
    const id = openTopicModal ? 'simple-popover' : undefined;

    // set the default selected topic in the state
    useEffect(() => {
        if (playerState.topicIndex < 0) {
            const topicIndex = topicList[0]?.id || 0;
            const topic = topicList[0]?.label || '';

            setPlayerState((state: PlayerState) => ({
                ...state,
                topicIndex,
                topic,
            }))
        }
    }, [playerState.topicIndex, setPlayerState, topicList]);

    return (
        <>
            <ButtonGroup color="inherit">
                <Button
                    id="topicMenuOpen"
                    variant="text"
                    aria-label='change topic'
                    size='small'
                    sx={{ marginLeft: '5px', color: palette.secondary.light, height: '20px' }}
                    onClick={handleTopicButtonClick}
                >
                    <FilterListIcon sx={{ marginRight: '8px' }} />
                    <FormattedMessage
                        id="app.playlist-topic-button"
                        defaultMessage="Topics"
                    />
                </Button>
                {
                    playerState.topicIndex > 0 &&
                    <Button
                        disabled
                        size='small'
                        variant="text"
                        sx={{ height: '20px' }}
                    >

                        <Typography
                            variant='caption'
                            color={palette.secondary.light}
                            sx={{
                                border: `1px solid ${palette.primary.dark}`,
                                borderRadius: 2,
                                ml: 1,
                                pt: '1px',
                                px: 2,
                            }}
                        >
                            <FormattedMessage
                                id={`app.speech-topics-id-${playerState.topicIndex}`}
                            />

                        </Typography>
                    </Button>
                }
            </ButtonGroup>
            <Menu
                id={id}
                open={openTopicModal}
                anchorEl={anchorEl}
                onClose={handleTopicSelectorClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    "&& .Mui-selected": {
                        color: palette.secondary.light,
                        backgroundColor: "white"
                    }
                }}
            >
                {
                    topics.map(topic => (
                        (
                            topic.id === 0 ||
                            languageAudios?.filter(
                                audio => (audio?.topicId === topic.id)
                            ).length > 0
                        ) &&
                        <MenuItem
                            id={`topic_${topicList[topic.id]?.code}`}
                            key={`topic_key_${topic.label}`}
                            selected={topic.id === playerState.topicIndex}
                            onClick={
                                event => {
                                    handleMenuItemClick(event, topic.id, topic.label)
                                }}
                        >
                            {
                                topic.id > -1 &&
                                <FormattedMessage
                                    id={`app.speech-topics-id-${topic.id}`}
                                    defaultMessage={topic.label ? topic.label : 'All'}
                                />
                            }
                            {
                                topic.id === undefined &&
                                <>{topic.label}</>
                            }
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
}
