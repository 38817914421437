import { useContext } from 'react';

// contexts
import { AppContext } from '../../../contexts/AppContext';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Card,
    CardContent,
    CardMedia,
    Stack,
    Typography,
} from "@mui/material";
import WatchIcon from '@mui/icons-material/Watch';

// handlers
import { formatDurationPunctuated } from "../../player/odiaPlayerHandlers";

// interfaces
import { PlayerAudio } from "../../../interfaces/player/player.interface";
import { PlaylistView } from '../../../types/playlistView';

// utils
import { formatDate } from '../../../utils/formatDate';


interface Props {
    audio: PlayerAudio,
    visualStyle: AudioDescriptorVisualStyle
}

interface AudioDescriptorVisualStyle {
    view: PlaylistView,
    showTitle: boolean,
    showDescription: boolean,
    showDate: boolean,
    showDuration: boolean,
    showPublisher: boolean,
    showTopic: boolean,
    showImage: boolean,
    truncateTitle: boolean,
    truncateDescription: boolean
}


export const AudioCard = ({ audio, visualStyle }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const {
        publisherTitle,
        imageUrl,
        publisherLabel,
        duration,
        selected,
        publisherPublicationDate
    } = audio;

    const {
        view,
        showDate,
        showDuration,
        showPublisher
    } = visualStyle;


    const cardSize = (view === 'detail' ? 180 : 220);
    const cardRadius = (view === 'detail' ? 3 : 9);
    const titleTruncateClass = (view === 'detail' ? 'title' : 'visual-title');
    const infoAreaStyle = (view === 'detail'
        ? {
            padding: 0,
            position: "absolute",
            width: cardSize,
            height: "65px",
            color: "white",
            left: 0,
            top: 115,
            background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0) 100%)',
            borderRadius: '3px'
        }
        : {
            padding: 0,
            position: "absolute",
            width: cardSize,
            height: 70,
            color: "white",
            left: 0,
            top: 155,
            background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0) 100%)',
            borderRadius: '3px'
        })

    return (
        <Card
            elevation={0}
            sx={{
                width: { cardSize },
                height: { cardSize },
                position: "relative",
                borderRadius: cardRadius,
                border: selected ? `2px solid ${palette.secondary.light}` : '2px solid rgba(0, 0, 0, 0.03)'
            }}
        >
            <CardMedia
                component="img"
                height={cardSize}
                image={imageUrl}
            />
            {
                showPublisher &&
                <CardContent
                    sx={{
                        background: 'rgba(0,0,0,0.25)',
                        borderRadius: 3,
                        direction: "column",
                        height: '21px',
                        justifyContent: "center",
                        alignContent: 'center',
                        left: 8,
                        p: 0,
                        position: "absolute",
                        top: 10
                    }}
                >
                    <Typography
                        aria-label='playlistAudioPublisherDate'
                        color='white'
                        component='div'
                        id="publisher"
                        sx={{ textShadow: '0px 1px 9px rgba(0,0,0,0.74)' }}
                        variant="subtitle2"
                    >
                        {publisherLabel}
                    </Typography>
                </CardContent>
            }
            <Stack
                direction='column'
                sx={infoAreaStyle}
            >
                <div id={titleTruncateClass}>
                    {/* <Typography
                        aria-label='label title'
                        color='white'
                        // variant={titleTruncateClass}
                    > */}
                    <b>{publisherTitle}</b>
                    {/* </Typography> */}
                </div>
                {
                    (showDate || showDuration)
                    &&
                    <div id="dateInfo">
                        <Stack direction='row'>
                            {
                                (
                                    showDate
                                ) &&
                                <Typography
                                    aria-label='playlistAudioPublisherDate'
                                    color='white'
                                    variant="caption"
                                    mr={6}
                                >
                                    {formatDate(publisherPublicationDate, currentContextLanguage)}
                                </Typography>
                            }
                            {
                                showDuration &&
                                duration > 0 &&
                                <Stack direction='row'>
                                    <WatchIcon sx={{ height: 15, paddingTop: "2px" }} />
                                    <Typography
                                        aria-label='playlistAudioDuration'
                                        color='white'
                                        variant="caption"
                                    >
                                        {formatDurationPunctuated(duration)}
                                    </Typography>
                                </Stack>
                            }
                        </Stack>
                    </div>
                }
            </Stack>
        </Card >
    )
}