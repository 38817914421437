
// components
import { CompactPlaylist } from '../../compactPlaylist/CompactPlaylist'
import { PlaylistPanel } from '../../playlistPanel/PlaylistPanel'

// mui
import { Box, Stack } from '@mui/material';


export const MobileLandscapeView = () => {
    return (
        <Stack
            direction='row'
            spacing={3}
            width='98vw'
        >
            <Box sx={{
                width: '40%',
                height: '100vh'
            }}>
                <CompactPlaylist mobileView={true} mobileViewOrientation='landscape' />
            </Box>
            <Box sx={{
                width: '60%',
                height: '100vh'
            }}>
                <PlaylistPanel view='listMobile' mobileViewOrientation='landscape' />
            </Box>
        </Stack>
    )
}
