import { PlayerAudio } from "../interfaces/player/player.interface";
import { Topic } from "../interfaces/player/playerRequest.interface";


export const getTopicId = (topic: string, topicList: Topic[]): number => {
    const topicData = topicList.filter(t => t.code.toLowerCase() === topic.toLowerCase())[0]
    const id = topicData ? topicData.id : 0;
    if (topicData === undefined) {
        console.log(`Topic not found: ${topic}`)
    }
    console.log(`Topic: ${topic} ID: ${id}`)

    return id;
}

export const getTopicDescription = (topicId: number, topicList: Topic[]): string => {
    const topicData = topicList.filter(t => t.id === topicId)[0]
    const description = topicData ? topicData.label : topicList[0].label;
    if (topicData === undefined) {
        return ""
    }
    console.log(`Topic ID: ${topicId} Description: ${description}`)
    return description;
}

export const getTopicsFromAudioList = (audioQueue: PlayerAudio[]): Topic[] => {
    const topics: Topic[] = []

    for (const audio of audioQueue) {
        if (!topics.some(t => t.label === audio.topicName)) {
            topics.push({ id: audio.topicId, code: audio.topicName, label: audio.topicName, thumbnailUrl: '' });
        }
    }

    return topics.sort()
}
