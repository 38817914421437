import { PlayerRequestValidation } from "../interfaces/player/playerRequest.interface"


export const applyURLStyle = (validationResult: PlayerRequestValidation): PlayerRequestValidation => {

    // if there is an url search param called theme, we have to change the theme mode of the playlist
    const urlParams = new URLSearchParams(window.location.search);
    const themeMode = urlParams.get('theme');
    if (themeMode && (themeMode === 'light' || themeMode === 'dark')) {
        validationResult.playerStyle.visual.themeMode = themeMode;
    }

    return validationResult;
}