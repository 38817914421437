// mui
import { useTheme } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ImageIcon from '@mui/icons-material/Image';

// interfaces
import { PlaylistPanelView } from '../../../../interfaces/playlist/playlistPanelView.interface';


interface Props {
    playlistView: string
    setPlaylistView: React.Dispatch<React.SetStateAction<PlaylistPanelView>>
}

export const ToggleViewButtons = ({ playlistView, setPlaylistView }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    return (
        <ToggleButtonGroup
            value={playlistView}
            sx={{
                mt: '-2px',
                height: '24px'
            }}
        >
            <ToggleButton
                value="list"
                onClick={() => setPlaylistView('list')}
            >
                <FormatListBulletedIcon
                    htmlColor={playlistView === 'list' ? palette.secondary.light : 'inherit'}
                    sx={{ fontSize: '15pt' }}
                />
            </ToggleButton>
            <ToggleButton
                value="covers"
                onClick={() => setPlaylistView('covers')}
            >
                <ImageIcon
                    htmlColor={playlistView === 'covers' ? palette.secondary.light : 'inherit'}
                    sx={{ fontSize: '15pt' }}
                />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
