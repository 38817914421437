// default types
import { defaultPlayerValidation } from '../types/default/defaultPlaylist';

// utils
import { getPlaylist } from './getPlaylist';
import { applyURLStyle } from './applyURLStyle';

// interfaces
import { PlayerRequestValidation } from '../interfaces/player/playerRequest.interface';
import { PlaylistRequestBody } from '../interfaces/playlist/playlistRequestBody.interface';
import { PublicPlaylistRequestParams } from '../interfaces/playlist/publicPlaylistRequest.interface';


const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

export const validatePublicPlaylistData = async (params: PublicPlaylistRequestParams): Promise<PlayerRequestValidation> => {
    const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';
    const backendAuth: string = process.env.REACT_APP_BACKEND_AUTH || '';

    defaultPlayerValidation.language = defaultLanguage;

    const requestUrl = `${backendRoute}/playlist/recent`;
    const publicPlaylist = true;
    const topicRequestUrl = `${backendRoute}/topics`;
    const requestBody: PlaylistRequestBody = {};

    const validationResult = await getPlaylist(
        requestUrl, publicPlaylist, params, requestBody, topicRequestUrl, backendAuth
    );

    return applyURLStyle(validationResult);
}
