
// mui
import NavigationIcon from '@mui/icons-material/Navigation';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SettingsIcon from '@mui/icons-material/Settings';
import RadioIcon from '@mui/icons-material/Radio';
import SettingsBluetoothIcon from '@mui/icons-material/SettingsBluetooth';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import EvStationIcon from '@mui/icons-material/EvStation';
import SmsIcon from '@mui/icons-material/Sms';
import { SvgIconProps } from '@mui/material';


interface Button {
    name: string,
    icon: React.ReactElement<SvgIconProps>,
    order: number,
    clickable: boolean
}


export const buttons: Button[] = [
    {
        name: "navigation",
        icon: <NavigationIcon sx={{ fontSize: 55 }} />,
        order: 1,
        clickable: false
    },
    {
        name: "phone",
        icon: <LocalPhoneIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: false
    },
    {
        name: "settings",
        icon: <SettingsIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: false
    },
    {
        name: "FM",
        icon: <RadioIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: false
    },
    {
        name: "bluetooth",
        icon: <SettingsBluetoothIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: false
    },
    {
        name: "news",
        icon: <NewspaperIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: true
    },
    {
        name: "trip computer",
        icon: <EvStationIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: false
    },
    {
        name: "messages",
        icon: <SmsIcon sx={{ fontSize: 55 }} />,
        order: 2,
        clickable: false
    },
]
