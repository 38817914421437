// components
import { CompactPlaylist } from '../../compactPlaylist/CompactPlaylist'
import { PlaylistPanel } from '../../playlistPanel/PlaylistPanel'


export const MobilePortraitView = () => {
    return (
        <>
            {/* Compact Playlist */}
            <CompactPlaylist mobileView={true} />

            {/* Playlist section */}
            <PlaylistPanel view='listMobile' />
        </>
    )
}
