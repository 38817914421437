import { useContext, useState } from 'react';

// modules
import { FormattedMessage } from "react-intl";

// contexts
import { PlayerContext } from '../../../contexts/PlayerContext';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Menu,
    MenuItem,
    Tooltip,
    Typography
} from '@mui/material';

// interfaces
import { PlayerProps } from '../../../interfaces/player/player.interface';
import { PlaybackRate } from '../../../interfaces/player/playbackRate.interface';

interface Props {
    playbackRates: PlaybackRate[]
}


export const SpeedRateSelectorMenu = ({ playbackRates }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const playerContext = useContext(PlayerContext);
    const { style, props, type: playerType } = playerContext;
    const { visual } = style;
    const { primaryColor } = visual;
    const color = palette.mode !== 'dark' ? primaryColor : palette.text.secondary

    const {
        playerState,
        setPlayerState,
    } = props as PlayerProps;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleShowMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectRate = (id: number) => {
        const rateFilter = playbackRates.filter(rate => rate.id === id)[0];

        if (rateFilter) {
            const {
                playbackRate,
                playbackRateLabel
            } = rateFilter

            setPlayerState(state => ({
                ...state,
                playbackRate,
                playbackRateLabel
            }))
        }

        handleClose();
    };

    const tooltipPlacement = playerType.mode === 'player' ? 'right' : 'top';

    return (
        <>
            <Tooltip
                placement={tooltipPlacement}
                title={<FormattedMessage id='app.player-playbackRate-button' />}
            >
                <Button
                    id="playback-button"
                    aria-controls={open ? 'playback-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleShowMenu}
                    sx={{ color }}
                >
                    {playerState.playbackRateLabel}
                </Button>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'playback-button' }}
            >
                {playbackRates.map(rate => (
                    <MenuItem
                        key={`rate-${rate.id}`}
                        onClick={() => handleSelectRate(rate.id)}
                    >
                        <Typography
                            color={
                                rate.playbackRate === playerState.playbackRate
                                    ? palette.secondary.light
                                    : 'inherit'
                            }
                        >
                            {rate.playbackRateLabel}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
