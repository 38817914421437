import { useContext, useEffect, useRef, useState } from 'react';

// contexts
import { AppContext } from '../../contexts/AppContext';
import { PlayerContext } from '../../contexts/PlayerContext';

// mui
import { useTheme } from '@mui/material/styles';
import { Fab } from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

// utils
import { fixMispronunciation } from '../../utils/fixMispronunciation';
import { getSpeech } from '../../utils/speech/getSpeech';
import { getSpeechCommands } from '../../utils/speech/getSpeechCommands';

// handlers
import { getActivateASRAudio } from '../speechPage/speechHandlers';

// types
import { defaultVoiceCommand } from '../../types/default/defaultVoiceCommand';
import { processCommand } from '../../utils/speech/processCommand';


export const SpeechButton = () => {
    const theme = useTheme();
    const { palette } = theme;
    const { appLang, appRecording, audioGuide, appTopics, appModals, externalCommand } = useContext(AppContext);

    const { lang } = appLang;
    const { current: currentLanguage } = lang;
    const {
        isRecording,
        setRecording,
        setVoiceCommand
    } = appRecording;
    const { audioGuideActivated, setAudioGuideActivated, setAudioGuideNextSrc } = audioGuide;
    const { setPlayAudioGuide } = externalCommand;
    const { speechCommands, speechGrammar } = getSpeechCommands(currentLanguage)

    const { topics: topicList } = appTopics;

    const { landingModal, topicModal } = appModals;
    const { openLandingModal } = landingModal;
    const { setOpenTopicModal } = topicModal;

    const playerContext = useContext(PlayerContext);

    const commands = useRef(speechCommands);
    const grammar = useRef(speechGrammar);
    let recognition = useRef(getSpeech(grammar.current, currentLanguage));

    //do not show voice command button on Firefox
    const showVoiceButton = (recognition.current !== null);

    const [playedIntroducionAudio, setPlayedIntroducionAudio] = useState(false);
    const [eventReceived, setEventReceived] = useState<any | null>(null);

    const initRecording = (isStarted: boolean) => {
        if (audioGuideActivated && !playedIntroducionAudio && !openLandingModal) {
            // play audio guide introduction message
            const introductionAudio: HTMLAudioElement = getActivateASRAudio(currentLanguage);
            introductionAudio.addEventListener("ended", () => {
                if (!isStarted) {
                    recognition.current.stop();
                    recognition.current.start();
                    setRecording(true);
                }
                setVoiceCommand(defaultVoiceCommand);
                setPlayedIntroducionAudio(true);
            });
            introductionAudio.play();
        }
        else {
            if (!isStarted) {
                recognition.current.stop();
                recognition.current.start();
                setRecording(true);
            }
            setVoiceCommand(defaultVoiceCommand);
        }

        recognition.current.onresult = (event: any) => { setEventReceived(event); }
    }


    const stopRecording = () => {
        setRecording(false);
        setVoiceCommand(defaultVoiceCommand);
        recognition.current.stop();
    }

    const handleStartSpeech = () => {
        if (!isRecording) initRecording(false);
    }

    const handleStopSpeech = () => {
        stopRecording();
    }


    useEffect(() => {
        if (showVoiceButton) {
            commands.current = speechCommands;
            grammar.current = speechGrammar;
            recognition.current = getSpeech(grammar.current, currentLanguage);
        }
        // eslint-disable-next-line
    }, [showVoiceButton, speechCommands, speechGrammar, currentLanguage])


    // update state when recording stops by any reason
    useEffect(() => {
        if (showVoiceButton) {
            recognition.current.removeEventListener('end', () => { });
            recognition.current.addEventListener('end', () => { stopRecording(); });
        }
        // eslint-disable-next-line
    }, [showVoiceButton])

    useEffect(() => {
        setAudioGuideActivated(audioGuideActivated);
        if (isRecording && !playedIntroducionAudio) {
            let audio = getActivateASRAudio(currentLanguage);
            audio.addEventListener("ended", () => {
                setVoiceCommand(defaultVoiceCommand);
                setPlayedIntroducionAudio(true);
            });
            audio.play();
        }
        // eslint-disable-next-line
    }, [audioGuideActivated, setAudioGuideActivated])

    useEffect(() => {
        if (eventReceived) {
            const lastResult = eventReceived.results.length - 1;
            let command = eventReceived.results[lastResult][0].transcript.trim().toLowerCase();
            const confidence = eventReceived.results[lastResult][0].confidence as number;
            let originalCommand = `${command}`;
            command = fixMispronunciation(command);

            console.info({ originalCommand, fixedCommand: command });

            processCommand(
                command,
                commands.current,
                confidence,
                currentLanguage,
                audioGuideActivated,
                playerContext,
                setVoiceCommand,
                topicList,
                setAudioGuideNextSrc,
                setPlayAudioGuide,
                setOpenTopicModal
            )
            setEventReceived(null);
        }
        // eslint-disable-next-line
    }, [eventReceived])

    const fabIconBaseStyle = {
        backgroundColor: 'white',
        position: 'relative',
        scale: '1.5',
        my: 1,
        zIndex: 2050
    }

    return (
        <>
            {
                showVoiceButton &&
                (
                    isRecording
                        ? <Fab
                            aria-label="stop-speech"
                            onClick={handleStopSpeech}
                            sx={fabIconBaseStyle}
                        >
                            <MicIcon htmlColor={palette.success.main} />
                        </Fab>
                        : <Fab
                            aria-label="start-speech"
                            onClick={handleStartSpeech}
                            sx={fabIconBaseStyle}
                        >
                            <MicOffIcon htmlColor={palette.secondary.light} />
                        </Fab>
                )
            }
        </>
    )
}
