export const updatePlaylistURL = (audioGuideActivated: boolean, themeMode: string, locationSearch: string, locationPathname: string) => {
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.set('theme', (themeMode === 'dark' ? 'dark' : 'light'));
    searchParams.set('audioGuide', (audioGuideActivated ? 'true' : 'false'));

    const newUrl = `${locationPathname}?${searchParams.toString()}`;

    window.history.pushState(null, '', newUrl);
}

export const updatePlaylistOnUrlChange = (setAudioGuideActivated: Function, setDarkMode: Function, setLightMode: Function, locationSearch: string) => {
    // if there is an url search param called theme, we have to change the theme mode of the playlist
    const searchParams = new URLSearchParams(locationSearch);
    const themeMode = searchParams.get('theme');
    if (themeMode) {
        if (themeMode === 'dark') {
            setDarkMode();
        } else {
            setLightMode();
        }
    }

    const audioGuideParam = searchParams.get('audioGuide');
    if (audioGuideParam) {
        setAudioGuideActivated(audioGuideParam === 'true');
    }
}