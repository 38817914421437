import { useEffect, useContext } from "react";

// modules
import { useLocation } from "react-router-dom";

// components
import { MobilePortraitView } from "./mobilePortraitView/MobilePortraitView";
import { MobileLandscapeView } from "./mobileLandscapeView/MobileLandscapeView";

// mui
import { useMediaQuery } from '@mui/material';

// context
import { AppContext } from "../../../contexts/AppContext";

// utils
import { updatePlaylistOnUrlChange, updatePlaylistURL } from "../updatePlaylist";


export const MobilePlaylist = () => {
    const location = useLocation();
    const { audioGuide, colorMode } = useContext(AppContext);
    const { setAudioGuideShown, setAudioGuideActivated, audioGuideActivated } = audioGuide;
    const { themeMode, setDarkMode, setLightMode } = colorMode;

    const isLandscapeView = useMediaQuery('(min-width:600px)');

    // update playlist on url change
    useEffect(() => {
        setAudioGuideShown(true);
        updatePlaylistOnUrlChange(setAudioGuideActivated, setDarkMode, setLightMode, location.search);
        // eslint-disable-next-line
    }, []);

    // update playlist url on language, theme or audio guide status change
    useEffect(() => {
        updatePlaylistURL(audioGuideActivated, themeMode.mode, location.search, location.pathname);
        // eslint-disable-next-line
    }, [themeMode, audioGuideActivated, location.search, location.pathname]);

    return (
        <>
            {
                isLandscapeView
                    ? <MobileLandscapeView />
                    : <MobilePortraitView />
            }
        </>
    )
}
