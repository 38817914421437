// interfaces
import { Command } from "../../interfaces/appContext/appContext.interface";

/**
 * parse external command and apply it on the context state
 * we get this messages frou outside the app, executing this line:
 * window.postMessage('{"langCode": "en-US", "command": "play"}', '*');
 * the command we get is the string: {"langCode": "en-US", "command": "play"}
 * we parse it to JSON to handle it in the context
 */
export const commandParser = (message: string, setExtCommand: React.Dispatch<React.SetStateAction<Command>>) => {
    try {
        // parse the message as json
        const jsonCommand = JSON.parse(message);

        if (
            jsonCommand?.command &&
            jsonCommand?.command.length > 0 &&
            jsonCommand?.langCode &&
            jsonCommand?.langCode.length > 0
        ) {
            setExtCommand({
                message: jsonCommand?.command,
                languageCode: jsonCommand?.langCode,
                pendingProcessing: true
            })
        }

    } catch (error) {
        console.error(`Could not parse the incoming command. Error: ${error}`);
    }
}
