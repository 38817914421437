import { handleSeekTo } from './playerHandlers';

export const handleSliderChange = (
    e: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[],
    setPosition: React.Dispatch<React.SetStateAction<number>>
) => {
    setPosition(newValue as number);
};

export const handleSliderChangeCommited = (
    e: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[],
    setPosition: React.Dispatch<React.SetStateAction<number>>,
    playerRef: React.MutableRefObject<null>
) => {
    setPosition(newValue as number);
    handleSeekTo(newValue as number, playerRef);
};

export const formatDuration = (value: number) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
}

export const formatDurationPunctuated = (value: number) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute}'${secondLeft < 10 ? `0${secondLeft}''` : `${secondLeft}"`}`;
}