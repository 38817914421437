import { useContext, useEffect } from 'react';

// modules
import { useLocation } from 'react-router-dom';

// components
import { CompactPlaylist } from '../compactPlaylist/CompactPlaylist';
import { PlaylistPanel } from '../playlistPanel/PlaylistPanel';
import { TopBar } from '../../topBar/TopBar';

// context
import { AppContext } from '../../../contexts/AppContext';
import { PlayerContext } from '../../../contexts/PlayerContext';

// utils
import { updatePlaylistOnUrlChange, updatePlaylistURL } from '../updatePlaylist';

// interfaces
import { PlaylistPanelView } from '../../../interfaces/playlist/playlistPanelView.interface';
import { PlayerStylePlaylist } from '../../../interfaces/player/player.interface';


interface Props {
    view: PlaylistPanelView
}

export const SimplePlaylist = ({ view }: Props) => {
    const { audioGuide, colorMode } = useContext(AppContext);
    const { setAudioGuideActivated, audioGuideActivated } = audioGuide;
    const { themeMode, setDarkMode, setLightMode } = colorMode;

    const location = useLocation();

    const playerContext = useContext(PlayerContext);
    const { style } = playerContext;
    const { visual, playlist } = style;
    const { showPlaylistHeader } = playlist as PlayerStylePlaylist;
    const { showOdiaLabel } = visual;


    // update playlist on url change
    useEffect(() => {
        updatePlaylistOnUrlChange(setAudioGuideActivated, setDarkMode, setLightMode, location.search);
        // eslint-disable-next-line
    }, []);

    // update playlist url on language, theme or audio guide status change
    useEffect(() => {
        updatePlaylistURL(audioGuideActivated, themeMode.mode, location.search, location.pathname);
        // eslint-disable-next-line
    }, [themeMode, audioGuideActivated, location.search, location.pathname]);

    return (
        <>
            {
                showPlaylistHeader &&
                view === "portal" &&
                <TopBar
                    visualStyle={{ showHomeButton: false, showOdiaLabel, linkToWebsite: true }}
                // mobileViewOrientation={mobileViewOrientation}
                />
            }
            {
                view === "portal" &&
                <>
                    {/* Playlist section */}
                    <PlaylistPanel view={view} />

                    {/* Compact Playlist */}
                    <CompactPlaylist view={view} />
                </>
            }
            {
                view !== "portal" &&
                <>
                    {/* Compact Playlist */}
                    <CompactPlaylist view={view} />

                    {/* Playlist section */}
                    <PlaylistPanel view={view} />
                </>
            }
        </>
    )
}
