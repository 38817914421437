import {
  createBrowserRouter,
  redirect
} from "react-router-dom";

// components
import { PlayerPage } from "../components/playerPage/PlayerPage";
import { ErrorPage } from "../components/errorPage/ErrorPage";
import { DemoPlayerPage } from "../components/demoPage/DemoPlayerPage";
import { DemoPlaylistPage } from "../components/demoPage/DemoPlaylistPage";

// services
import { validatePlayerData } from "../services/validatePlayerData";
import { validatePlaylistData } from "../services/validatePlaylistData";
import { PublicPlaylistRequestParams } from "../interfaces/playlist/publicPlaylistRequest.interface";
import { validatePublicPlaylistData } from "../services/validatePublicPlaylistData";

// lang
import { languages } from "../translations/languages";


const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

export const BrowserRouter = createBrowserRouter([
  // base path route
  {
    path: "/",
    element: <ErrorPage />,
    errorElement: <ErrorPage />
  },

  // player redirect: no language
  {
    path: "/:userId/:fileId",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { userId, fileId } = params.params;

      const url = new URL(params.request.url);
      const type: string = url.searchParams.get('type') || '';
      const color: string = url.searchParams.get("color") || '';

      let redirectRoute = `/${defaultLanguage}/${userId}/${fileId}`;
      if (type.length > 0 && color.length > 0) redirectRoute += `?type=${type}&color=${color}`;
      else if (color.length > 0) redirectRoute += `?color=${color}`;
      else if (type.length > 0) redirectRoute += `?type=${type}`;

      return redirect(redirectRoute)
    }
  },

  // player route with language, user and file
  {
    path: "/:lang/:userId/:fileId",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '', userId, fileId } = params.params;
      const url = new URL(params.request.url);
      const adTag = url.searchParams.get("adtag") || '';

      if (!languages.includes(lang)) {

        const type: string = url.searchParams.get('type') || '';
        const color: string = url.searchParams.get("color") || '';

        let redirectRoute = `/${defaultLanguage}/${userId}/${fileId}`;
        if (type.length > 0 && color.length > 0) redirectRoute += `?type=${type}&color=${color}`;
        else if (color.length > 0) redirectRoute += `?color=${color}`;
        else if (type.length > 0) redirectRoute += `?type=${type}`;

        return redirect(redirectRoute);
      }

      return validatePlayerData(params, false, adTag);
    }
  },

  // playlist redirect: no language
  {
    path: "/:userId/playlist",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { userId } = params.params;

      const url = new URL(params.request.url);
      const type: string = url.searchParams.get("type") || '';
      const color: string = url.searchParams.get("color") || '';
      const playlistLength: string = url.searchParams.get("playlistLength") || '';
      const maxDaysOld: string = url.searchParams.get("maxDaysOld") || '';

      let redirectRoute = `/${defaultLanguage}/${userId}/playlist`;

      let linkParams = "";
      if (type.length > 0) redirectRoute += `type=${type}`;
      if (color.length > 0) redirectRoute += `&color=${color}`;
      if (playlistLength.length > 0) redirectRoute += `&playlistLength=${playlistLength}`;
      if (maxDaysOld.length > 0) redirectRoute += `&maxDaysOld=${maxDaysOld}`;
      if (type.length === 0 && linkParams.length > 0) linkParams = linkParams.substring(1);
      if (linkParams.length > 0) redirectRoute += `?${linkParams}`;

      return redirect(redirectRoute);
    }
  },

  // playlist route with language and user
  {
    path: "/:lang/:userId/playlist",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '', userId } = params.params;
      if (!languages.includes(lang)) {
        const url = new URL(params.request.url);
        const type: string = url.searchParams.get("type") || '';
        const color: string = url.searchParams.get("color") || '';
        const playlistLength: string = url.searchParams.get("playlistLength") || '';
        const maxDaysOld: string = url.searchParams.get("maxDaysOld") || '';

        let redirectRoute = `/${defaultLanguage}/${userId}/playlist`;

        let linkParams = "";
        if (type.length > 0) redirectRoute += `type=${type}`;
        if (color.length > 0) redirectRoute += `&color=${color}`;
        if (playlistLength.length > 0) redirectRoute += `&playlistLength=${playlistLength}`;
        if (maxDaysOld.length > 0) redirectRoute += `&maxDaysOld=${maxDaysOld}`;
        if (type.length === 0 && linkParams.length > 0) linkParams = linkParams.substring(1);
        if (linkParams.length > 0) redirectRoute += `?${linkParams}`;

        return redirect(redirectRoute);
      }
      return validatePlaylistData(params);
    }
  },

  // playlist route with language, user and publisher
  {
    path: "/:lang/:userId/:publisher/playlist",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '', userId } = params.params;
      if (!languages.includes(lang)) {
        const url = new URL(params.request.url);
        const type: string = url.searchParams.get("type") || '';
        const color: string = url.searchParams.get("color") || '';
        const playlistLength: string = url.searchParams.get("playlistLength") || '';
        const maxDaysOld: string = url.searchParams.get("maxDaysOld") || '';

        let redirectRoute = `/${defaultLanguage}/${userId}/playlist`;

        let linkParams = "";
        if (type.length > 0) redirectRoute += `type=${type}`;
        if (color.length > 0) redirectRoute += `&color=${color}`;
        if (playlistLength.length > 0) redirectRoute += `&playlistLength=${playlistLength}`;
        if (maxDaysOld.length > 0) redirectRoute += `&maxDaysOld=${maxDaysOld}`;
        if (type.length === 0 && linkParams.length > 0) linkParams = linkParams.substring(1);
        if (linkParams.length > 0) redirectRoute += `?${linkParams}`;

        return redirect(redirectRoute);
      }
      return validatePlaylistData(params);
    }
  },

  // playlist route with language and user
  {
    path: "/:lang/:userId/playlist/:playlistName",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '', userId, playlistName = '' } = params.params;
      if (!languages.includes(lang)) {
        const url = new URL(params.request.url);
        const type: string = url.searchParams.get("type") || '';
        const color: string = url.searchParams.get("color") || '';
        const theme: string = url.searchParams.get("theme") || '';

        let redirectRoute = `/${defaultLanguage}/${userId}/playlist/${playlistName}`;

        let linkParams = "";
        if (type.length > 0) linkParams += `type=${type}`;
        if (color.length > 0) linkParams += `&color=${color}`;
        if (theme.length > 0) linkParams += `&theme=${theme}`;
        if (type.length === 0 && linkParams.length > 0) linkParams = linkParams.substring(1);
        if (linkParams.length > 0) redirectRoute += `?${linkParams}`;

        return redirect(redirectRoute);
      }
      return validatePlaylistData(params)
    }
  },


  // playlist route for the public articles playlist
  {
    path: "/:lang/publicPlaylist",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '' } = params.params;
      const url = new URL(params.request.url);

      if (!languages.includes(lang)) {
        const theme: string = url.searchParams.get("theme") || '';

        let redirectRoute = `/${defaultLanguage}/publicPlaylist`;

        let linkParams = "";
        if (theme.length > 0) linkParams += `type=${theme}`;
        if (linkParams.length > 0) redirectRoute += `?${linkParams}`;

        return redirect(redirectRoute);
      }
      const maxDaysOld: string = url.searchParams.get("maxDaysOld") || '';
      const maxArticles: string = url.searchParams.get("maxArticles") || '';

      const newParams: PublicPlaylistRequestParams = {
        maxDaysOld: maxDaysOld !== '' ? parseInt(maxDaysOld) : 3,
        maxArticlesPerPublisher: maxArticles !== '' ? parseInt(maxArticles) : 12
      }
      return validatePublicPlaylistData(newParams);
    }
  },


  // playlist route for publisher articles playlist
  {
    path: "/:lang/:userId/dynamicPlaylist",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '', userId } = params.params;

      const url = new URL(params.request.url);

      if (!languages.includes(lang)) {
        const theme: string = url.searchParams.get("theme") || '';

        let redirectRoute = `/${defaultLanguage}/${userId}/dynamicPlaylist`;

        let linkParams = "";
        if (theme.length > 0) linkParams += `type=${theme}`;
        if (linkParams.length > 0) redirectRoute += `?${linkParams}`;

        return redirect(redirectRoute);
      }

      const maxDaysOld: string = url.searchParams.get("maxDaysOld") || '';
      const maxArticles: string = url.searchParams.get("maxArticles") || '';

      const newParams: PublicPlaylistRequestParams = {
        maxDaysOld: maxDaysOld !== '' ? parseInt(maxDaysOld) : 7,
        publisher: userId,
        maxArticlesPerPublisher: maxArticles !== '' ? parseInt(maxArticles) : 12
      }
      return validatePublicPlaylistData(newParams);
    }
  },

  // playlist route for the dynamic articles playlist, given a specific article
  {
    path: "/:lang/:userId/:fileId/dynamicPlaylist",
    element: <PlayerPage />,
    errorElement: <ErrorPage />,
    loader: async (params) => {
      const { lang = '', userId, fileId } = params.params;
      if (!languages.includes(lang)) {
        const url = new URL(params.request.url);
        const type: string = url.searchParams.get('type') || '';
        const color: string = url.searchParams.get("color") || '';

        let redirectRoute = `/${defaultLanguage}/${userId}/${fileId}`;
        if (type.length > 0 && color.length > 0) redirectRoute += `?type=${type}&color=${color}`;
        else if (color.length > 0) redirectRoute += `?color=${color}`;
        else if (type.length > 0) redirectRoute += `?type=${type}`;

        return redirect(redirectRoute);
      }

      // retrieve initial article data
      const initialArticleDataRes = await validatePlayerData(params);

      if (initialArticleDataRes.isValidated) {
        let audio = initialArticleDataRes.playerData.audioQueue[0];

        audio.publisherLabel = audio.publisher;

        // get a recent playlist of the client
        const newParams: PublicPlaylistRequestParams = {
          maxDaysOld: 3,
          publisher: audio.publisher
        }
        let playlistRes = await validatePublicPlaylistData(newParams);

        // remove the initial article in the playlist if it contains it
        playlistRes.playerData.audioQueue = playlistRes.playerData.audioQueue.filter(function (e) { return e.id !== audio.id })

        // add the initial article audio at the beginning of the playlist audio queue
        playlistRes.playerData.audioQueue.unshift(audio);

        // set the design to compact not to display the list of articles
        playlistRes.playerType.design = "compact";

        return playlistRes;
      }
    }
  },

  // demoPlayer redirect: no language
  {
    path: "/demoPlayer",
    loader: () => { return redirect(`/${defaultLanguage}/demoPlayer`) }
  },

  // demoPlayer route
  {
    path: "/:lang/demoPlayer",
    element: <DemoPlayerPage />,
    errorElement: <ErrorPage />,
    loader: (params) => {
      const { lang = '' } = params.params;
      const demo = true;
      if (!languages.includes(lang)) {
        return redirect(`/${defaultLanguage}/demoPlayer`)
      }

      return validatePlayerData(params, demo);
    }
  },

  // demoPlaylist redirect: no language
  {
    path: "/demoPlaylist",
    loader: () => { return redirect(`/${defaultLanguage}/demoPlaylist`) }
  },

  // demoPlaylist route
  {
    path: "/:lang/demoPlaylist",
    element: <DemoPlaylistPage />,
    errorElement: <ErrorPage />,
    loader: (params) => {
      const { lang = '' } = params.params;
      const demo = true;
      if (!languages.includes(lang)) {
        return redirect(`/${defaultLanguage}/demoPlaylist`)
      }
      return validatePlaylistData(params, demo)
    }
  },
]);