const lexicon = {
    "audio": "odia",
    "hello": "hey",
    "hi": "hey",
    "informational": "information",
    "plate": "play",
    "serons": "cerence",
    "silence": "cerence",
    "sirens": "cerence",
    "siri": "cerence",
    "stevens": "cerence",
    "syrians": "cerence",
    "there": "odia",
    "toyota": "hey odia",
    "future": "culture",
}


export const fixMispronunciation = (command: string): string => {
    for (const key in lexicon) {
        const expr = new RegExp(`\\b${key}\\b`)
        if (command.match(expr)) {
            command = command.replace(expr, lexicon[key as keyof typeof lexicon])
        }
    }

    return command;
}
