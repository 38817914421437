import axios from 'axios';


export const registerConsumption = async (audioFileDuration: number, playedSeconds: number[], audioName: string, clientName: string, publisherName: string,
    topicName: string, autoPlay: boolean): Promise<boolean> => {

    const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';
    const backendAuth: string = process.env.REACT_APP_BACKEND_AUTH || '';
    const consumptionUrl = `${backendRoute}/consumption/registerConsumption`

    const countOfPlayedSeconds = playedSeconds.length - 1;

    if (countOfPlayedSeconds > 0) {
        const postData = {
            audioName,
            clientName,
            audioDuration: audioFileDuration,
            playedDuration: countOfPlayedSeconds,
            publisherName,
            topicName,
            autoPlay,
        }

        const resp = await axios(consumptionUrl,
            {
                headers: { 'apikey': backendAuth },
                method: 'post',
                data: postData
            }
        );

        if (resp.status !== 200) return false;
    }
    return true;
}
