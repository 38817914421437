import { useContext } from "react";

// modules
import { FormattedMessage } from "react-intl";

// contexts
import { PlayerContext } from "../../../contexts/PlayerContext";

// mui
import { Box, Typography } from '@mui/material';


export const ListenLabel = () => {
    const playerContext = useContext(PlayerContext);

    const { style } = playerContext;
    const { visual } = style;
    const { primaryColor } = visual;


    return (
        <Box
            sx={{
                backgroundColor: `${primaryColor}`,
                borderRadius: '3px',
                display: 'flex',
                px: '3px',
            }}
        >
            <Typography
                variant="caption"
                noWrap
                sx={{
                    background: 'inherit',
                    backgroundClip: 'text',
                    color: 'transparent',
                    filter: 'invert(1) grayscale(1) contrast(9)',
                    fontWeight: 600,
                    opacity: 1
                }}
            >
                <FormattedMessage id='app.player-listen-label' />
            </Typography>
        </Box>
    )
}
