interface FormatOptions {
    year: string,
    month: string,
    day: string,
    hour?: string,
    minute?: string
}

const options: FormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE || 'en-US';

export const formatDate = (inputDate: string, locale = defaultLanguage, displayTime = false): string => {
    const date = new Date(inputDate);

    let formatOptions: FormatOptions = {
        year: options.year,
        month: options.month,
        day: options.day
    }
    if (displayTime) {
        formatOptions = {
            ...formatOptions,
            hour: options.hour,
            minute: options.minute
        }
    }

    const formattedDate = date.toLocaleDateString(locale, formatOptions as object);
    return formattedDate;
}
