// interfaces
import { PlayerCustom } from '../interfaces/player/playerTypes.interface';

// defaults
import { defaultButtons, defaultLanguage, defaultVisual } from './default/defaultProps';


export const playerTypes: PlayerCustom[] = [
    {
        type: {
            id: 1,
            mode: 'player',
            design: 'simple',
            description: 'Default Simple Player',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual
            },
            buttons: {
                ...defaultButtons
            },
            language: {
                ...defaultLanguage
            }
        }
    },
    {
        type: {
            id: 2,
            mode: 'player',
            design: 'compact',
            description: 'Default compact player',
            autoplay: false
        },
        style: {
            visual: {
                ...defaultVisual,
                backgroundColor: '#F0F0F0',
                boxShadow: '0px 10px 17px -13px rgba(0,0,0,0.38)',
                buttonsSize: 'small',
                primaryColor: '#1963d1',
                secondaryColor: '#7F7F7F',
                playButtonIconSize: '2rem',
                showOdiaLabel: false,
                showPlayerTitle: false,
                showPlayerDescription: false,
                showCarDisplayModal: false,
            },
            buttons: {
                ...defaultButtons,
                showPlayerForwardButton: false,
                showPlayerRewindButton: false,
            },
            language: {
                ...defaultLanguage,
                showLanguageName: false,
                displayRow: false
            }
        }
    }
]